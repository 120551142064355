@media (min-width: 2501px) and (max-width: 3500px) {
  .home-hero .title .heading-h1 .small {
    left: 660px;
  }
}
@media (min-width: 1922px) and (max-width: 2500px) {
  .home-hero .title .heading-h1 .small {
    left: 660px;
  }
}
@media (min-width: 1801px) and (max-width: 1921px) {
  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 200px) !important;
  }

  .home-hero .title .heading-h1 .small {
    left: 670px;
  }
}

@media (min-width: 1600px) and (max-width: 1800px) {
  :root {
    --h1-font-size: 50px;
    --h1-line-height: 60px;

    --h2-font-size: 40px;
    --h2-line-height: 48px;

    --h3-font-size: 24px;
    --h3-line-height: 30px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 18px;
    --h5-line-height: 30px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 16px;
    --body-line-height: 24px;

    --login-title: 50px;
    --login-line-height: 56px;
  }

  .contact-us-main .contact-row {
    display: grid;
    grid-template-columns: 37.5% auto;
    gap: 4.5%;
    margin-bottom: 150px;
  }

  .admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.order-list85 .tb-res-v1 {
    width: 120%;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .home-hero .hero-left .sub-title {
    position: relative;
    padding-top: 477px;
  }

  .login-main-wrap {
    padding-top: 294px;
    max-width: 535px;
  }

  .admin-panel-main .dash-filter.filter .left-fil {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 6px;
  }

  .dash-filter.filter .custom-dropdown::after {
    right: 7px;
    top: 18px;
    width: 11px;
    height: 8px;
  }

  .fiction-slide-card .card-image {
    width: 100%;
    height: 510px;
  }

  .fiction-slide-card .hero-hover {
    top: 7px;
    z-index: 2;
    height: 513px;
    width: 100%;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 12px;
    width: calc(100% - 70px);
  }

  .demand-card .card-image {
    width: 400px;
    height: 606px;
  }

  .demand-card .hero-hover {
    z-index: 2;
    height: 606px;
    width: 100%;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 10px 12px;
    width: calc(100% - 70px);
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 509.13px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 509.13px;
  }

  .overview-card-row .fiction-slide-card .card-image,
  .overview-card-row .fiction-slide-card .hero-hover {
    height: 450px !important;
    width: 298px !important;
  }

  .plan-card {
    padding: 30px 41px 51px;
    border-radius: 10px;
  }

  .join-questions-main .q-card {
    height: 380px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
  }

  .cost-order-tab {
    width: 35%;
  }

  .demand-card .number p {
    font-size: 320px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 290px;
    display: grid;
    grid-template-columns: 42.1% 58%;
  }

  .home-hero .title .heading-h1 .small {
    top: 22px;
    left: 60%;
  }

  .subscription-main .cost-tab {
    grid-template-columns: repeat(auto-fill, 157px);
    width: 85%;
  }

  .fiction-slide-card .detail .book-name {
    padding: 7px 0;
    width: 100%;
    height: 68px;
  }

  .our-story-s2 .top-main {
    width: 76%;
    padding-right: 18%;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 35%;
  }

  .product-detail {
    grid-template-columns: 42.5% 57.5%;
    padding: 0 60px;
    width: 84.5%;
  }

  .detail.demand-slider {
    width: 84.5%;
    padding: 0 60px;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 100px;
    margin-bottom: 49px;
  }

  .product-detail .add-cart-cta {
    margin-bottom: 5px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 50px !important;
  }

  .our-story-main {
    width: 84.5%;
    padding: 392px 60px 0;
  }

  .our-story-s1 {
    padding: 0 60px;
  }

  .our-story-s2 {
    width: 84.5%;
    padding: 150px 60px 50px;
  }

  .overview-card-row {
    gap: 40px;
    margin-top: 187px;
    padding-left: 60px;
    padding-right: 60px;
    grid-template-columns: repeat(4, 22.7%);
  }

  .overview-filter-main {
    width: 84.5%;
    padding: 20px 60px 10px;
  }

  .overview-footer {
    padding: 50px 60px 100px;
  }

  .choose-plan-main {
    padding: 0 60px;
  }

  .join-questions-main {
    width: 84.5%;
    padding: 50px 60px;
  }

  .contact-us-main {
    width: 84.5%;
    padding: 0 60px;
    margin-top: -239px;
  }

  .library-overview-hero {
    padding-left: 60px;
  }

  .t-and-c-main {
    grid-template-columns: 26.5% 67%;
    width: 84.5%;
    padding: 0 60px 125px;
    gap: 6.5%;
  }

  .subscription-main {
    padding: 0 60px;
  }

  .our-story-s2 .left-s2 .image2 {
    margin-right: 15px;
  }

  .h-before-footer .house-row .cta {
    margin-right: 7px;
  }

  .h-before-footer .title .heading-h2::after {
    width: 34%;
    right: -140px;
  }

  .home-hero .new-to-library::before {
    width: 85.5%;
    height: 200px;
  }

  .home-hero .new-to-library .new-to {
    margin-top: 127px;
    grid-template-columns: 1fr 81px;
  }

  .home-hero .new-to-library .explore {
    margin-right: 80px;
    margin-top: 5px;
  }

  .h-before-footer .house-row {
    max-width: 60%;
  }

  .h-before-footer .detail {
    margin-bottom: 24px;
    word-spacing: 0px;
    letter-spacing: 0;
  }

  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 180px) !important;
  }

  .most-in-demand .top-title {
    grid-template-columns: 18% 56.8% 11.2%;
    padding-left: 60px;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 21% 50.8% 13.2%;
    padding-left: 60px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 14% 57.8% 12.2%;
    gap: 10px;
    padding-left: 60px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 60px;
  }

  .house_container {
    width: 84.5%;
    padding-right: 60px;
  }

  .picked-titles {
    padding: 82px 0 108px;
    padding-left: 60px;
    width: 84.5%;
  }

  #splide02-list #splide02-slide01 .demand-card .number {
    left: 49px !important;
  }

  .home-hero {
    padding-left: 60px;
  }

  .header-logo-main {
    padding-left: 60px;
  }

  .house-footer {
    padding: 66px 0px 87px 60px;
  }

  .product-detail .category-list ul {
    column-gap: 24px;
    row-gap: 10px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 30.5% 66%;
    margin-top: 45px;
    gap: 7.5%;
  }

  .demand-card .content .detail .book-name {
    padding: 5px 0 7px;
    width: 100%;
    -webkit-line-clamp: 2;
    height: 69px;
  }
}

@media (min-width: 1469px) and (max-width: 1599px) {
  /* .hero-slider .splide__arrows.splide__arrows--ttb {
        top: 70 !important;
    } */

  .admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.order-list85 .tb-res-v1 {
    width: 120%;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 17px;
  }

  .home-hero .title .heading-h1 .small {
    top: 11px !important;
    left: 66% !important;
  }

  .custom-dropdown::after {
    top: 56px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  :root {
    /* style guide for 1440 */
    --h1-font-size: 40px;
    --h1-line-height: 50px;

    --h2-font-size: 40px;
    --h2-line-height: 48px;

    --h3-font-size: 24px;
    --h3-line-height: 30px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 18px;
    --h5-line-height: 30px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 16px;
    --body-line-height: 24px;

    --login-title: 50px;
    --login-line-height: 56px;
  }

  .admin-panel-data.list_refunds-v1 .top-title,
  .admin-panel-data.list_refunds-v1 .book-row {
    grid-template-columns: 11% 15% 5.5% 5.5% 8% 7% 8% 6% 7% 7% 4% 11% !important;
    gap: 10px !important;
  }

  .admin-panel-data.list_refunds-v1 .tb-res-v1 {
    width: 250%;
  }

  .custom-dropdown .dropdown-options .option {
    font-size: 13px;
    line-height: 13px;
    font-family: var(--indivisible);
    padding-top: 6px;
    padding: 5px 15px;
  }

  .literary-cart .literary-row .schedule-return {
    margin-bottom: 20px;
  }

  .admin-panel-data.order-list85 .book-list .book-row,
  .admin-panel-data.order-list85 .top-title {
    grid-template-columns: 5% 5% 8% 16% 14% 4.5% 10% 11% 6% 9.5% 8% !important;
    gap: 5px;
  }

  .admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.order-list85 .tb-res-v1 {
    width: 205%;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .admin-panel-data.borrowed-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.borrowed-list85 .tb-res-v1 {
    width: 140%;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .login-main-wrap {
    padding-top: 294px;
    max-width: 493px;
  }

  .dash-filter.filter .custom-dropdown::after {
    right: 5px;
    top: 19px;
    width: 11px;
    height: 8px;
  }

  .admin-panel-main .dash-filter.filter .left-fil {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 7px;
  }

  .admin-panel-main .left .admin-menu-main {
    padding: 20px 10px !important;
    margin-top: 20px;
    max-height: 39vh;
  }

  .product-detail .add-to-cart:hover {
    background: var(--dark-green);
    padding: 13px 15px;
    color: var(--white);
  }

  .custom-dropdown .selected-option {
    padding: 45px 26px 7px 16px;
    font-size: 17px;
    line-height: 26px;
  }

  .custom-dropdown::after {
    right: 8px;
    top: 58px;
    width: 11px;
    height: 8px;
  }

  .selected-option.category::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.publisher::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.author::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.popular::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .library-overview-hero.detail-v1 .right {
    margin-bottom: 50px;
  }

  .admin-panel-data.order-list85 .custom-dropdown {
    width: 116px;
  }

  .custom-upload-file .fileUpload.btn.btn--browse {
    padding: 13px 11px;
    font-size: 13px;
  }

  .hero-slider .splide__arrows.splide__arrows--ttb {
    top: 70vh;
  }

  .library-overview-hero.faq .overview-title {
    padding-bottom: 80px;
  }

  .join-questions-main.faq {
    margin-top: 30px;
    padding-bottom: 120px;
  }

  .home-hero .sub-title .heading-h4 {
    font-size: 18px;
    line-height: 30px;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 60px);
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 60px);
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 60px);
  }

  .library-overview-hero.detail .reviews {
    margin-top: 11px;
  }

  .product-detail .product {
    width: 432px;
    height: 700px;
  }

  .library-overview-hero::after {
    display: none;
  }

  .h-before-footer .house-row .cta {
    margin-right: 8px;
  }

  .web-header .social-left .searchdiv {
    right: 16px;
    top: -7px;
    width: 350px;
  }

  .web-header .social-left .show-on-click {
    padding: 10px 20px;
    top: 46px;
    right: 16px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 437px;
  }

  /* Admin Panel */

  .book-panel-form .pan-form-row {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }

  .add-remove-input .make {
    padding: 0px 27px 2px 7px;
    border-radius: 3px;
    font-size: 14px;
    background-size: 11px;
    background-position: 91% 7px;
  }

  .add-remove-input .remove {
    padding: 2px 30px 2px 6px;
    border-radius: 3px;
    font-size: 15px;
    background-size: 10px;
    background-position: 90% 9px;
  }

  .admin-panel-main .panel-row .right {
    width: 79.5%;
    padding-right: 40px;
  }

  .admin-panel-main .panel-row .left {
    height: 100vh;
    width: 19%;
    padding: 30px 20px;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a {
    font-size: 16px;
    padding: 8.5px 8px;
    grid-template-columns: 29px 1fr;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a img {
    width: 18px;
  }

  .admin-panel-main .panel-row .bottom {
    width: 230px;
    bottom: 36px;
    left: 22px;
  }

  .admin-panel-main .right .panel-title-top.all-book {
    grid-template-columns: 40% 60%;
    margin-top: 55px;
    width: 100%;
  }

  .admin-panel-main .dash-filter.filter .search {
    padding-left: 160px;
  }

  .books-pagination {
    margin: 40px 0 30px;
  }

  .admin-panel-main .dash-filter.filter {
    top: 55px;
    grid-template-columns: 66% 34%;
    left: 0;
    padding: 0px 40px 0 0;
  }

  .admin-panel-data .top-title .top-t {
    font-size: 15px;
    line-height: 1.2em;
  }

  .admin-panel-data .top-title {
    grid-template-columns: 5% 12% 23% 12% 12% 6% 8% 7% 7%;
    gap: 1%;
    padding: 55px 16px 10px;
  }

  .admin-panel-data .book-list .book-row {
    grid-template-columns: 5% 12% 23% 12% 12% 6% 8% 7% 7%;
    gap: 1%;
    padding: 10px 16px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .admin-panel-data .book-list .title-value .top-value {
    font-size: 14px;
    line-height: 20px;
  }

  .e-status-value .toggle-on {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-status-value .toggle-off {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-edit-delete-value .edit-action img {
    width: 20px;
  }
  /* end */

  /* my account */
  .my-account-hero .ac-sub-title {
    padding-top: 280px;
    margin-bottom: 30px;
    padding-right: 60px;
  }

  .subscription-main.account .cost-tab {
    grid-template-columns: repeat(auto-fill, 150px);
    width: 100%;
    gap: 15px;
    border: 0px;
  }

  .profile1-main .s-plan {
    width: 62%;
  }

  .profile1-m1 .payment-done80 {
    width: 80%;
  }

  .my-account-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .profile1-m1 .pro1-title {
    margin-bottom: 0px;
  }

  .profile1-m1 {
    position: relative;
    padding: 30px 0 100px;
  }

  .my-account-hero .ac-title {
    max-width: 70%;
    padding-bottom: 47px;
  }

  .profile1-main {
    padding: 50px 0 130px;
  }

  .profile1-m1 .p1-detail {
    width: 90%;
    padding-bottom: 20px;
  }

  .profile1-m1 .pro1-tab {
    width: 90%;
    gap: 60px;
  }

  .profile1-m1 .current-stack15 {
    width: 90%;
  }

  .order-history .tab-content {
    width: 90%;
    padding: 10px 0;
  }

  .cost-order-tab {
    width: 40%;
  }

  .profile1-m1 .reading-list15 {
    width: 90%;
  }

  /* end */

  /* our-story */
  .our-story-main {
    width: 84.5%;
    padding: 320px 60px 0;
  }

  .our-story-s1 {
    width: 84.5%;
    padding: 0 60px;
  }

  .our-story-s2 {
    width: 84.5%;
    padding: 100px 60px 40px;
  }

  .our-story-s2 .top-main {
    width: 77%;
    padding-right: 9%;
  }

  .our-story-s1 .r-image .img-1 {
    width: 440px;
    height: 410px;
    border-radius: 15px;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 15%;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 294px;
    width: 100%;
    margin-top: 35px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 28.5% 66%;
    margin-top: 40px;
    gap: 5.5%;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 171px;
    width: 173px;
    border-radius: 15px;
    margin-top: 22px;
    margin-right: 0px;
  }

  .our-story-s1 .s1-left .s-content {
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  .our-story-s1 .s1-row .right .r-image {
    margin-top: 100px;
    gap: 18px;
  }

  .our-story-main .blank-bottom {
    height: 160px;
    width: 85%;
    margin-top: 68px;
  }

  .our-story-main .story-title {
    width: 78%;
    margin-top: 23px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 7px;
    line-height: 1.6em;
  }

  /* End */

  /* order */

  .place-order-card {
    border-radius: 10px;
    padding: 22px;
  }

  .order-page-hero .hero-s-title {
    padding-top: 230px;
  }

  .order-page-hero::after {
    width: 96.5%;
    height: 364px;
    bottom: -1px;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    position: relative;
    height: 90px;
    text-align: left;
    font-size: 18px;
    padding: 43px 15px 5px;
  }

  #OrderNowMain-tab-OrderNow::after {
    left: 20px;
    top: 16px;
  }

  .literary-cart .delivery {
    padding-right: 30px;
    margin-top: 20px;
  }

  .subscription-main {
    width: 84.5%;
    padding: 0 60px;
  }

  .literary-cart .top .top-heading::after {
    right: 0px;
    top: 25px;
    width: 57%;
    height: 2px;
  }

  .literary-cart .order-book-card {
    grid-template-columns: 135px 1fr;
    gap: 20px;
    padding: 30px 0;
  }

  .literary-cart .literary-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .order-book-card .top-row {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .literary-cart {
    display: grid;
    grid-template-columns: 59% 39%;
    gap: 2%;
    margin-top: 5px;
  }

  .literary-cart .order-book-card .book {
    width: 126px;
    height: 200px;
  }

  /* end */

  .thanku-page-main {
    width: 76%;
    padding-top: 360px;
  }

  /* term */
  .library-overview-hero.terms .overview-subtitle {
    padding-top: 270px;
    padding-bottom: 90px;
    width: 50%;
  }

  .t-and-c-main {
    margin-top: 90px;
    grid-template-columns: 31.5% 65%;
    width: 84.5%;
    padding: 0 60px 90px;
    gap: 3%;
  }

  .t-and-c-main .left-main {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .t-and-c-main .right-content {
    padding-right: 98px;
  }
  /* end */

  .web-header .social-left .header-whatsapp {
    height: 30px;
    width: 30px;
  }

  .detail.demand-slider {
    width: 84.5%;
    position: relative;
    padding: 0 60px;
    margin-bottom: 50px;
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 437px;
  }

  .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
  }

  .custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
  }

  .overview-filter-main .filter-row .left-area {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .custom-dropdown {
    width: 100%;
  }

  .overview-filter-main .filter-row {
    grid-template-columns: 78% 22%;
    gap: 0%;
  }

  .overview-filter-main .overview-top-title {
    grid-template-columns: 74% 22%;
    gap: 4%;
  }

  .fiction-slide-card .detail .book-name {
    padding: 7px 0;
    height: 70px;
    width: 100%;
  }

  /* Contact Us */
  .contact-us-main {
    width: 84.5%;
    padding: 0 60px;
    margin-top: 90px;
  }

  .library-overview-hero.contact-us .overview-detail {
    padding-bottom: 83px;
  }

  .contact-us-main .left .address {
    border-radius: 10px;
    padding: 54px 26px 39px;
  }

  .contact-us-main .in-touch .sub-title {
    margin-bottom: 48px;
    margin-top: 6px;
  }

  .contact-us-main .contact-row {
    grid-template-columns: 41.5% 1fr;
    gap: 3.5%;
    margin-bottom: 110px;
  }

  .contact-us-main .in-touch .title {
    margin-bottom: 30px;
  }

  .contact-us-main .right {
    padding-right: 8px;
  }

  /* join us */
  .library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 71%;
  }

  .join-questions-main {
    width: 84.5%;
    padding: 50px 60px;
  }

  .library-overview-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .choose-plan-main .plan-row {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 95%;
    row-gap: 66px;
  }

  .choose-plan-main {
    width: 84.5%;
    padding: 0 40px;
    margin-top: 100px;
    margin-bottom: 45px;
  }

  .library-overview-hero.join-us .overview-detail {
    margin-top: 26px;
    padding-bottom: 80px;
  }

  .library-overview-hero.join-us::after {
    width: 94.5%;
    height: 306px;
    bottom: -2px;
  }

  .faq-accordion .accordion-button {
    padding: 30px 50px;
    font-size: 22px;
    line-height: 34px;
  }

  .faq-accordion .accordion-body {
    padding: 12px 50px 30px;
  }

  .plan-card {
    padding: 35px 30px 50px;
  }

  .join-questions-main .q-card p {
    font-size: 35px;
    line-height: 44px;
    max-width: 44%;
    padding: 77px 0 39px 52px;
  }

  .join-questions-main .q-card {
    height: 275px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .join-questions-main .faq-row {
    grid-template-columns: 31% 67%;
    gap: 2%;
  }

  /* library detail */
  .product-detail .product {
    width: 374px;
    height: 565px;
  }

  .product-detail {
    grid-template-columns: 42.5% 57.5%;
    padding: 0 60px;
    width: 84.5%;
    padding-top: 80px;
  }

  .product-detail .book-category {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .library-overview-hero.detail::after {
    width: 40.5%;
    bottom: -2px;
    height: 80px;
  }

  .product-detail .notify-btn {
    padding: 13px 45px;
    margin-bottom: 10px;
  }

  .product-detail .add-to-cart {
    padding: 13px 45px;
    margin-bottom: 10px;
  }

  .product-detail .reading-cart {
    color: var(--dark-green);
    padding: 12px 45px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 45px !important;
  }

  .product-detail .add-cart-cta {
    grid-template-columns: 260px 1fr;
    gap: 30px;
  }

  .product-detail .category-list ul {
    column-gap: 25px;
    row-gap: 10px;
  }

  .you-like-title::after {
    width: 62%;
    height: 2px;
    right: 60px;
    top: 26px;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 70px;
    margin-bottom: 40px;
  }

  .overview-card-row.detail {
    margin-bottom: 60px;
  }

  .overview-card-row.detail .fiction-slide-card .detail {
    padding: 30px 20px 20px;
  }

  .product-detail .book-detail .detail-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px !important;
  }

  .product-detail .book-detail {
    padding: 12px 0 42px;
    margin-top: 20px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 260px;
    grid-template-columns: 42.5% 58%;
  }

  /* end */

  /* Library overview */
  .overview-card-row {
    gap: 30px;
    grid-template-columns: repeat(4, 23%);
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 70px;
    width: 84.5%;
  }

  .overview-footer {
    position: relative;
    width: 84.5%;
    padding: 40px 60px 80px;
  }

  .library-overview-hero .overview-detail {
    margin-top: 15px;
    padding-bottom: 80px;
  }

  .overview-filter-main {
    width: 84.5%;
    padding: 20px 60px 10px;
    margin-top: 25px;
  }

  .overview-card-row .fiction-slide-card .card-image,
  .overview-card-row .fiction-slide-card .hero-hover {
    height: 382px !important;
    width: 253px !important;
  }

  .overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 40px);
  }

  .library-overview-hero .overview-subtitle {
    padding-top: 250px;
    margin-bottom: 0;
  }

  .library-overview-hero .overview-title {
    margin-top: 20px;
  }

  /* end */

  .home-hero .hero-row {
    grid-template-columns: repeat(1, 1fr 330px);
    gap: 0px;
  }

  .header-logo-main .header-logo {
    width: 187px;
    height: 190px;
  }

  .header-logo-main {
    grid-template-columns: repeat(1, 230px 360px);
    gap: 2px;
    padding-left: 60px;
    width: 100%;
  }

  .house-header .new-cta-s1 {
    justify-content: end;
    padding-right: 50px;
  }

  .web-header .left-sidebar .cost-link {
    font-size: 16px;
    line-height: 35px;
    padding-top: 31px;
  }

  .web-header .social-left .top {
    padding-top: 31px;
  }

  .web-header .left-sidebar .link {
    width: 40px;
  }
  .web-header .social-left {
    width: 80px;
  }

  .hero-book-card .card-image {
    height: 495px;
  }

  .hero-book-card .hero-hover {
    height: 495px;
  }

  .hero-book-card .sub-title p {
    font-size: 13px;
    line-height: 21px;
  }

  .hero-book-card .sub-title {
    padding-top: 30px;
    padding-bottom: 11px;
  }

  .home-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .house_container {
    width: 84%;
    padding-right: 60px;
  }

  .home-hero .hero-left .sub-title {
    padding-top: 394px;
    padding-bottom: 19px;
  }

  .home-hero .hero-left .title {
    padding: 0px 0 20px;
  }

  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 150px) !important;
  }

  .hero-slider #splide01-track {
    padding-top: 100px !important;
  }

  .hero-book-card .detail {
    padding-bottom: 39px;
    height: 60px !important;
  }

  .h-before-footer .house-row {
    max-width: 68%;
  }

  .home-hero::after {
    height: 80px;
    width: 37.5%;
    border-radius: 0 10px 0 0;
    bottom: -1px;
  }

  #splide02-list #splide02-slide01 .demand-card .number {
    left: -5px !important;
    position: absolute;
  }

  .home-hero .new-to-library::before {
    width: 85.5%;
    height: 163px;
    border-radius: 10px 10px 0 0;
  }

  .most-in-demand .top-title {
    grid-template-columns: 21% 49.8% 14%;
    gap: 10px;
    padding-left: 60px;
  }

  .demand-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .hero-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .home-hero .hero-slider {
    height: 100vh;
  }

  .home-hero .hero-left .disc {
    position: relative;
    margin-top: 15px;
  }

  .home-hero .disc p {
    max-width: 68%;
  }

  .home-hero .title .heading-h1 .small {
    font-size: 32px;
    line-height: 55px;
    font-weight: 100 !important;
    top: 18px;
    left: 69%;
  }

  .home-hero .new-to-library .explore {
    margin-right: 165px;
    margin-top: 5px;
    padding-bottom: 70px;
  }

  .home-hero .new-to-library .new-to {
    grid-template-columns: 1fr 88px;
    margin-top: 138px;
    margin-right: 77px;
  }

  .home-hero .new-to-library .blank::after {
    width: 40px;
    left: 30%;
    top: 16px;
  }

  .demand-card .card-image {
    width: 330px;
    height: 495px;
    border-radius: 15px;
  }

  .demand-card .hero-hover {
    height: 495px;
    width: 83%;
    border-radius: 15px;
  }

  .demand-card .content {
    max-width: 397px;
  }

  .demand-card .number p {
    font-size: 270px;
  }

  .demand-card .number {
    left: -25px;
    top: 210px;
  }

  .demand-card .content .detail {
    padding: 22px 10px 20px;
  }

  .most-in-demand.repeat {
    padding-top: 30px;
  }

  .most-in-demand.repeat.fiction {
    margin-top: 48px;
    margin-bottom: 50px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 14% 58.8% 12.2%;
    gap: 10px;
  }

  .most-in-demand {
    padding-top: 56px;
  }

  .demand-slider .splide__arrows.splide__arrows--ltr {
    position: absolute;
    right: 0;
    top: -86px;
    gap: 10px;
    display: inline-flex;
  }

  .demand-slider {
    position: relative;
    margin-top: 45px;
  }

  .most-in-demand .top-title .blank p {
    width: 96.6%;
    height: 2px;
    margin-bottom: 12px !important;
  }

  .fiction-slide-card .card-image {
    width: 100%;
    height: 430px;
    border-radius: 15px;
  }

  .fiction-slide-card .hero-hover {
    position: absolute;
    top: 7px;
    z-index: 2;
    height: 430px;
    width: 100%;
    border-radius: 15px;
  }

  .fiction-slide-card .detail {
    padding: 22px 20px;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 22% 49.8% 13%;
    gap: 10px;
    padding-left: 60px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 60px;
  }

  .picked-titles {
    padding: 46px 0 102px;
    padding-left: 60px;
    width: 84.5%;
  }

  .picked-titles .picked-card.dark img {
    width: 171px;
  }

  .picked-titles .picked-card .picked-title {
    font-size: 110px;
    line-height: 70px;
    padding-bottom: 22px;
    padding-top: 61px;
  }

  .picked-titles .picked-card .detail {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 17px;
  }

  .picked-titles .picked-card .cta {
    padding: 163px 0 0;
  }

  .picked-titles .picked-card {
    border-radius: 10px;
    padding: 24px;
  }

  .becoming-member {
    padding: 229px 0 109px;
    width: 84.5%;
  }

  .becoming-member .content .heading-h2 {
    padding: 18px 0px 46px 0;
    margin-bottom: 21px;
    line-height: 48px !important;
  }

  .web-header {
    padding-top: 30px;
  }

  .h-before-footer .title .heading-h2::after {
    width: 37%;
    right: -99px;
    bottom: 15px;
    height: 2px;
  }

  .h-before-footer .detail {
    margin-bottom: 13px;
  }

  .h-before-footer .title .heading-h2 {
    margin-bottom: 20px;
  }

  .h-before-footer {
    padding: 103px 0 99px;
    width: 84.5%;
  }

  .house-footer {
    padding: 60px 0px 87px 60px;
    border-top: 0;
  }

  .house-footer::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1.5px;
    background: var(--yellow);
    left: 0;
    top: 0;
  }

  .house-footer .footer-top {
    grid-template-columns: 18% 19% 18% 31%;
    gap: 40px;
  }

  .house-footer .reserved {
    gap: 15px;
  }

  .house-footer .footer-bottom {
    grid-template-columns: 60% 40%;
    margin-top: 44px;
  }

  .becoming-member .content {
    max-width: 52%;
  }

  .house-footer .reserved p {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .privacy-policy a {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .f-detail .our-story li {
    margin-bottom: 15px;
  }

  .demand-card .content .detail .book-name {
    padding: 5px 0 7px;
    height: 67px;
  }

  .admin-panel-data.order-list85 .custom-dropdown .selected-option {
    padding: 2px 12px 1px;
    font-size: 13px;
    line-height: 26px;
  }

  .admin-panel-data.order-list85 .custom-dropdown::after {
    right: 15px;
    top: 13px;
    width: 11px;
    height: 8px;
  }

  .admin-panel-data .book-list .title-value .top-value {
    padding-right: 20px;
  }

  .suits89ty {
    width: 80%;
  }

  .admin-panel-data.subscription-list .tb-res-v1 {
    width: 230% !important;
  }

  .admin-panel-data.subscription-list .tb-res-v1 .book-row,
  .admin-panel-data.subscription-list .tb-res-v1 .top-title {
    gap: 10px !important;
    grid-template-columns: 10% 16% 7% 7% 8% 8% 8% 8% 7% 15% !important;
  }
}

@media (min-width: 1360px) and (max-width: 1399px) {
  :root {
    /* style guide for 1440 */
    --h1-font-size: 40px;
    --h1-line-height: 50px;

    --h2-font-size: 40px;
    --h2-line-height: 48px;

    --h3-font-size: 24px;
    --h3-line-height: 30px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 18px;
    --h5-line-height: 30px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 16px;
    --body-line-height: 24px;

    --login-title: 50px;
    --login-line-height: 55px;
  }

  .admin-panel-data.list_refunds-v1 .top-title,
  .admin-panel-data.list_refunds-v1 .book-row {
    grid-template-columns: 11% 15% 5.5% 5.5% 8% 8% 7% 5% 7% 8% 4% 11% !important;
    gap: 10px !important;
  }

  .admin-panel-data.list_refunds-v1 .tb-res-v1 {
    width: 230%;
  }

  .custom-dropdown .dropdown-options .option {
    font-size: 13px;
    line-height: 13px;
    font-family: var(--indivisible);
    padding-top: 6px;
    padding: 5px 15px;
  }

  .literary-cart .literary-row .schedule-return {
    margin-bottom: 20px;
  }

  .admin-panel-data.order-list85 .book-list .book-row,
  .admin-panel-data.order-list85 .top-title {
    grid-template-columns: 5% 5% 8% 16% 14% 4.5% 10% 11% 6% 9.5% 8% !important;
    gap: 5px;
  }

  .admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.order-list85 .tb-res-v1 {
    width: 220%;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .admin-panel-data.borrowed-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.borrowed-list85 .tb-res-v1 {
    width: 150%;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .home-hero .title .heading-h1 {
    max-width: 98%;
  }

  .login-main-wrap {
    padding-top: 294px;
    max-width: 493px;
  }

  .admin-panel-main .dash-filter.filter .left-fil {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
  }

  .custom-dropdown .selected-option {
    padding: 45px 16px 7px;
    font-size: 16px;
    line-height: 26px;
  }

  .custom-dropdown::after {
    right: 5px;
    top: 56px;
    width: 11px;
    height: 8px;
  }

  .selected-option.category::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.publisher::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.author::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.popular::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .library-overview-hero.detail-v1 .right {
    margin-bottom: 50px;
  }

  .admin-panel-data.subscription-list .book-list .book-row {
    grid-template-columns: 14.5% 15.5% 14% 8% 6% 7% 14% 14%;
    gap: 1%;
  }

  .admin-panel-data.subscription-list .top-title {
    grid-template-columns: 14.5% 15.5% 14% 8% 6% 7% 14% 14%;
    gap: 1%;
  }

  .admin-panel-data.order-list85 .custom-dropdown {
    width: 116px;
  }

  .custom-upload-file .fileUpload.btn.btn--browse {
    padding: 13px 11px;
    font-size: 13px;
  }

  .library-overview-hero.faq .overview-title {
    padding-bottom: 80px;
  }

  .join-questions-main.faq {
    margin-top: 30px;
    padding-bottom: 120px;
  }

  .home-hero .sub-title .heading-h4 {
    font-size: 18px;
    line-height: 30px;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 60px);
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 60px);
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 60px);
  }

  .library-overview-hero::after {
    display: none;
  }

  .web-header .social-left .searchdiv {
    right: 16px;
    top: -10px;
    width: 350px;
  }

  .web-header .social-left .show-on-click {
    padding: 10px 20px;
    top: 46px;
    right: 16px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 403.13px;
  }

  /* Admin Panel */

  .book-panel-form .pan-form-row {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }

  .add-remove-input .make {
    padding: 0px 27px 2px 7px;
    border-radius: 3px;
    font-size: 11px;
    background-size: 11px;
    background-position: 91% 7px;
  }

  .add-remove-input .remove {
    padding: 2px 30px 2px 6px;
    border-radius: 3px;
    font-size: 13px;
    background-size: 10px;
    background-position: 90% 9px;
  }

  .admin-panel-main .panel-row .right {
    width: 79%;
    padding-right: 40px;
  }

  .admin-panel-main .dash-filter.filter .search {
    padding-left: 110px;
  }

  .admin-panel-main .panel-row .left {
    height: 100vh;
    width: 19%;
    padding: 30px 20px;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a {
    font-size: 16px;
    padding: 8.5px 6px;
    grid-template-columns: 29px 1fr;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a img {
    width: 18px;
  }

  .admin-panel-main .panel-row .bottom {
    width: 215px;
    bottom: 38px;
    left: 24px;
  }

  .books-pagination {
    margin: 40px 0 30px;
  }

  .admin-panel-main .dash-filter.filter {
    top: 55px;
    grid-template-columns: 65% 35%;
    left: 0;
    padding: 0px 40px 0 0;
  }

  .admin-panel-data .top-title .top-t {
    font-size: 15px;
    line-height: 1.2em;
  }

  .dash-filter.filter .custom-dropdown::after {
    right: 7px;
    top: 18px;
    width: 11px;
    height: 8px;
  }

  .admin-panel-data .top-title {
    grid-template-columns: 5% 13% 23% 11% 12% 6% 8% 7% 7%;
    gap: 1%;
    padding: 55px 16px 10px;
  }

  .admin-panel-data .book-list .book-row {
    grid-template-columns: 5% 13% 23% 11% 12% 6% 8% 7% 7%;
    gap: 1%;
    padding: 10px 16px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .e-status-value .toggle-on {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-status-value .toggle-off {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-edit-delete-value .edit-action img {
    width: 20px;
  }

  /* end */

  /* my account */
  .subscription-main.account .cost-tab {
    grid-template-columns: repeat(auto-fill, 150px);
    width: 100%;
    gap: 15px;
    border: 0px;
  }

  .profile1-main .s-plan {
    width: 72%;
  }

  .profile1-m1 .payment-done80 {
    width: 85%;
  }

  .my-account-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .profile1-m1 .pro1-title {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .order-book-card {
    grid-template-columns: 125px 1fr;
    gap: 30px;
    padding: 20px 0;
  }

  .order-book-card .book-name {
    margin-top: 5px;
  }

  .order-book-card .cta {
    margin-top: 5px;
  }

  .order-book-card .book-name h3 {
    margin-bottom: 0px;
  }

  .profile1-m1 {
    position: relative;
    padding: 30px 0 100px;
  }

  .my-account-hero .ac-title {
    max-width: 70%;
    padding-bottom: 47px;
  }

  .profile1-main {
    padding: 50px 0 130px;
  }

  .profile1-m1 .p1-detail {
    width: 90%;
    padding-bottom: 20px;
  }

  .profile1-m1 .pro1-tab {
    width: 90%;
    gap: 40px;
  }

  .profile1-m1 .current-stack15 {
    width: 90%;
  }

  .order-history .tab-content {
    width: 90%;
    padding: 10px 0;
  }

  .cost-order-tab {
    width: 40%;
  }

  .profile1-m1 .reading-list15 {
    width: 90%;
  }

  .my-account-hero .ac-sub-title {
    padding-top: 250px;
    margin-bottom: 30px;
    padding-right: 60px;
  }
  /* end */

  /* our-story */
  .our-story-main {
    width: 84.5%;
    padding: 320px 60px 0;
  }

  .our-story-s1 {
    width: 84.5%;
    padding: 0 60px;
  }

  .our-story-s2 {
    width: 84.5%;
    padding: 100px 60px 40px;
  }

  .our-story-s2 .top-main {
    width: 79%;
    padding-right: 7%;
  }

  .our-story-s1 .r-image .img-1 {
    width: 440px;
    height: 388px;
    border-radius: 15px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-right {
    height: 220px;
  }

  .our-story-main::after {
    height: 90px;
    width: 38.5%;
    border-radius: 0 10px 0 0;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 11%;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 294px;
    width: 100%;
    margin-top: 35px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 28.5% 66%;
    margin-top: 40px;
    gap: 5.5%;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 171px;
    width: 173px;
    border-radius: 15px;
    margin-top: 22px;
    margin-right: 0;
  }

  .our-story-s1 .s1-left .s-content {
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  .our-story-s1 .s1-row .right .r-image {
    margin-top: 100px;
    gap: 18px;
  }

  .our-story-main .blank-bottom {
    height: 130px;
    width: 85%;
    margin-top: 68px;
  }

  .our-story-s1 .s1-row {
    grid-template-columns: 33% 62.7%;
    gap: 4.3%;
  }

  .our-story-main .story-title {
    width: 78%;
    margin-top: 19px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 7px;
    line-height: 1.6em;
  }

  .our-story-s1 .s1-left .s-title {
    max-width: 88%;
    margin-bottom: 18px;
    line-height: 1.2em;
  }

  /* End */

  /* order */

  .place-order-card {
    border-radius: 10px;
    padding: 22px;
  }

  .order-page-hero .hero-s-title {
    padding-top: 230px;
  }

  .order-page-hero::after {
    width: 96.5%;
    height: 364px;
    bottom: -1px;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    position: relative;
    height: 90px;
    text-align: left;
    font-size: 18px;
    padding: 43px 15px 5px;
  }

  #OrderNowMain-tab-OrderNow::after {
    left: 20px;
    top: 16px;
  }

  .literary-cart .delivery {
    padding-right: 30px;
    margin-top: 20px;
  }

  .subscription-main {
    width: 84.5%;
    padding: 0 60px;
  }

  .literary-cart .top .top-heading::after {
    right: 0px;
    top: 25px;
    width: 57%;
    height: 2px;
  }

  .literary-cart .order-book-card {
    grid-template-columns: 140px 1fr;
    gap: 20px;
    padding: 30px 0;
  }

  .literary-cart .literary-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .order-book-card .top-row {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .order-book-card .top-row.previous-v1 {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

  .literary-cart {
    display: grid;
    grid-template-columns: 59% 39%;
    gap: 2%;
    margin-top: 5px;
  }

  .literary-cart .order-book-card .book {
    width: 126px;
    height: 200px;
  }

  /* end */

  .thanku-page-main {
    width: 76%;
    padding-top: 360px;
  }

  /* term */
  .library-overview-hero.terms .overview-subtitle {
    padding-top: 270px;
    padding-bottom: 90px;
    width: 50%;
  }

  .t-and-c-main {
    margin-top: 80px;
    grid-template-columns: 31.5% 65%;
    width: 84.5%;
    padding: 0 60px 90px;
    gap: 3%;
  }

  .t-and-c-main .left-main {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .t-and-c-main .right-content {
    padding-right: 60px;
  }
  /* end */

  .detail.demand-slider {
    width: 84.5%;
    position: relative;
    padding: 0 60px;
    margin-bottom: 50px;
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 394.13px;
  }

  .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
  }

  .custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
  }

  .overview-filter-main .filter-row .left-area {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .custom-dropdown {
    width: 100%;
  }

  .overview-filter-main .filter-row {
    grid-template-columns: 73% 26%;
    gap: 1%;
  }

  .overview-filter-main .overview-top-title {
    grid-template-columns: 70% 26%;
    gap: 4%;
  }

  .fiction-slide-card .detail .book-name {
    padding: 7px 0;
    margin-bottom: 0;
    width: 100%;
    height: 69px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  /* Contact Us */
  .contact-us-main {
    width: 84.5%;
    padding: 0 60px;
    margin-top: 90px;
  }

  .library-overview-hero.contact-us .overview-detail {
    padding-bottom: 75px;
  }

  .contact-us-main .left .address {
    border-radius: 10px;
    padding: 54px 26px 39px;
  }

  .contact-us-main .in-touch .sub-title {
    margin-bottom: 48px;
    margin-top: 6px;
  }

  .contact-us-main .contact-row {
    grid-template-columns: 44.5% 1fr;
    gap: 4.5%;
    margin-bottom: 110px;
  }

  .contact-form .sign-form-col .w-55 {
    width: 100%;
  }

  .contact-form .sign-form-col .w-45 {
    width: 49%;
  }

  .contact-form .cost-form-group {
    grid-template-columns: 1fr 1fr;
  }

  .contact-us-main .in-touch .title {
    margin-bottom: 30px;
  }

  .contact-us-main .right {
    padding-right: 8px;
  }

  .library-overview-hero.contact-us::after {
    height: 305px;
    width: 96%;
    bottom: -2px;
  }

  .contact-us-main .left .inquiries {
    margin-bottom: 24px;
  }

  /* join us */
  .library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 78%;
  }

  .join-questions-main {
    width: 84.5%;
    padding: 50px 60px;
  }

  .library-overview-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .choose-plan-main .plan-row {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 98%;
    row-gap: 60px;
  }

  .choose-plan-main {
    padding: 0 60px;
    margin-top: 70px;
    margin-bottom: 35px;
  }

  .library-overview-hero.join-us .overview-detail {
    margin-top: 26px;
    padding-bottom: 86px;
  }

  .join-questions-main .q-card {
    height: 250px;
    border-radius: 10px;
    margin-top: 10px;
  }

  .library-overview-hero.join-us::after {
    width: 94.5%;
    height: 306px;
    bottom: -2px;
  }

  .faq-accordion .accordion-button {
    padding: 30px 50px;
    font-size: 19px;
    line-height: 1.2em;
  }

  .faq-accordion .accordion-body {
    padding: 12px 50px 30px;
  }

  .plan-card {
    padding: 35px 55px 50px;
  }

  .join-questions-main .q-card p {
    font-size: 35px;
    line-height: 44px;
    max-width: 44%;
    padding: 65px 0 38px 45px;
  }

  .join-questions-main .faq-row {
    grid-template-columns: 31% 67%;
    gap: 2%;
  }

  /* library detail */
  .product-detail .product {
    width: 374px;
    height: 607px;
  }

  .product-detail {
    grid-template-columns: 45.5% 54.5%;
    padding: 0 60px;
    width: 84.5%;
    padding-top: 80px;
  }

  .product-detail .book-category {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .library-overview-hero.detail::after {
    width: 41.5%;
    bottom: -2px;
    height: 80px;
  }

  .product-detail .notify-btn {
    padding: 13px 25px;
    margin-bottom: 10px;
  }

  .product-detail .add-to-cart {
    padding: 13px 40px;
    margin-bottom: 10px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 40px !important;
  }

  .product-detail .add-to-cart:hover {
    padding: 13px 19px;
  }

  .product-detail .reading-cart {
    color: var(--dark-green);
    padding: 12px 40px;
  }

  .product-detail .add-cart-cta {
    grid-template-columns: 240px 1fr;
    gap: 30px;
  }

  .product-detail .category-list ul {
    column-gap: 23px;
    row-gap: 10px;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .overview-card-row.detail {
    margin-bottom: 60px;
  }

  .overview-card-row.detail .fiction-slide-card .detail {
    padding: 30px 20px 20px;
  }

  .product-detail .book-detail .detail-title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 15px !important;
  }

  .product-detail .book-detail {
    padding: 12px 0 42px;
    margin-top: 20px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 260px;
    grid-template-columns: 45.5% 54%;
  }

  .you-like-title::after {
    width: 57%;
    height: 2px;
    right: 60px;
    top: 25px;
  }

  /* end */

  /* Library overview */
  .overview-card-row {
    margin-top: 250px;
    padding-left: 60px;
    padding-right: 60px;
    width: 84.5%;
    grid-template-columns: repeat(4, 23%);
    gap: 30px;
  }

  .overview-footer {
    position: relative;
    width: 84.5%;
    padding: 40px 60px 70px;
  }

  .library-overview-hero .overview-detail {
    margin-top: 0px;
    padding-bottom: 80px;
  }

  .overview-filter-main {
    width: 84.5%;
    padding: 20px 60px 10px;
    margin-top: 28px;
  }

  .library-overview-hero .overview-subtitle {
    padding-top: 250px;
    margin-bottom: 0;
  }

  .library-overview-hero .overview-title {
    margin-top: 10px;
  }

  .overview-card-row .fiction-slide-card .card-image,
  .overview-card-row .fiction-slide-card .hero-hover {
    height: 352px !important;
    width: 237.88px !important;
  }

  .overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 40px);
  }

  .library-overview-hero::after {
    height: 90px;
    width: 95%;
    left: 0;
    bottom: -2px;
    border-radius: 0 10px 0 0;
  }

  .overview-card-row .fiction-slide-card .detail {
    padding: 20px 11px 7px;
  }

  /* end */

  .home-hero .hero-row {
    grid-template-columns: repeat(1, 1fr 280px);
    gap: 0px;
  }

  .header-logo-main .header-logo {
    width: 187px;
    height: 190px;
  }

  .header-logo-main {
    grid-template-columns: repeat(1, 230px 360px);
    gap: 2px;
    padding-left: 60px;
    width: 100%;
  }

  .house-header .new-cta-s1 {
    justify-content: end;
    padding-right: 50px;
  }

  .web-header .left-sidebar .cost-link {
    font-size: 16px;
    line-height: 35px;
    padding-top: 31px;
  }

  .web-header .social-left .top {
    padding-top: 31px;
  }

  .web-header .left-sidebar .link {
    width: 40px;
  }
  .web-header .social-left {
    width: 80px;
  }

  .hero-book-card .card-image {
    height: 430px;
  }

  .hero-book-card .hero-hover {
    height: 430px;
  }

  .hero-book-card .sub-title p {
    font-size: 13px;
    line-height: 21px;
  }

  .hero-book-card .sub-title {
    padding-top: 23px;
    padding-bottom: 5px;
  }

  .home-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .house_container {
    width: 84%;
    padding-right: 60px;
  }

  .home-hero .hero-left .sub-title {
    padding-top: 455px;
  }

  .home-hero .hero-left .title {
    padding: 18px 0 36px;
  }

  /* .hero-slider .splide__track .splide__list li {
        height: calc(100% - 310px) !important;
    } */

  .admin-panel-main .left .admin-menu-main {
    padding: 20px 10px !important;
    margin-top: 20px;
    max-height: 39vh;
  }

  .hero-slider #splide01-track {
    padding-top: 250px !important;
  }

  .hero-slider .splide__arrows.splide__arrows--ttb {
    right: -38px;
    bottom: auto;
    top: 3.5%;
  }

  .hero-book-card .detail {
    padding-bottom: 10px;
  }

  .h-before-footer .house-row {
    max-width: 61%;
  }

  .h-before-footer .house-row .cta {
    margin-right: 31px;
  }

  .home-hero::after {
    height: 80px;
    width: 39.5%;
    border-radius: 0 10px 0 0;
    bottom: -1px;
  }

  .web-header .social-left .header-whatsapp {
    height: 30px;
    width: 30px;
  }

  .home-hero .new-to-library::before {
    width: 85.5%;
    height: 160px;
    border-radius: 10px 10px 0 0;
  }

  .most-in-demand .top-title {
    grid-template-columns: 21% 50.8% 12%;
    gap: 10px;
    padding-left: 60px;
  }

  .demand-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .hero-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .home-hero .hero-slider {
    height: 99vh;
  }

  .home-hero .disc p {
    max-width: 76%;
  }

  .home-hero .title .heading-h1 .small {
    font-size: 32px;
    line-height: 55px;
    font-weight: 100 !important;
    top: 11px;
    left: 70%;
  }

  .home-hero .new-to-library .explore {
    margin-right: 105px;
    margin-top: 2px;
    padding-bottom: 60px;
  }

  .home-hero .new-to-library .new-to {
    grid-template-columns: 1fr 88px;
    margin-top: 189px;
    margin-right: 20px;
  }

  .home-hero .new-to-library .blank::after {
    width: 40px;
    left: 30%;
    top: 16px;
  }

  .demand-card .card-image {
    width: 330px;
    height: 495px;
    border-radius: 15px;
  }

  .demand-card .hero-hover {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 495px;
    width: 93%;
  }

  .demand-card .content {
    max-width: 356px;
  }

  .demand-card .number p {
    font-size: 260px;
  }

  .demand-card .content .detail .book-name {
    padding: 5px 0 7px;
    width: 100%;
    height: 68px;
    -webkit-line-clamp: 2;
  }

  #splide02-list #splide02-slide01 .demand-card .number {
    left: -20px !important;
    position: absolute;
  }

  .demand-card .number {
    left: -36px;
    top: 210px;
  }

  .demand-card .content .detail {
    padding: 28px 10px 20px;
  }

  .most-in-demand.repeat {
    padding-top: 30px;
  }

  .most-in-demand {
    padding-top: 58px;
  }

  .most-in-demand .top-title .title {
    margin-bottom: -9px;
    line-height: 48px;
  }

  .demand-slider .splide__arrows.splide__arrows--ltr {
    top: -82px;
    gap: 10px;
  }

  .demand-slider {
    position: relative;
    margin-top: 42px;
  }

  .most-in-demand.repeat.fiction {
    margin-top: 53px;
    margin-bottom: 24px;
  }

  .most-in-demand .top-title .blank p {
    width: 96.6%;
    height: 2px;
    margin-bottom: 12px !important;
  }

  .fiction-slide-card .card-image {
    width: 100%;
    height: 400px;
    border-radius: 15px;
  }

  .fiction-slide-card .hero-hover {
    position: absolute;
    top: 7px;
    z-index: 2;
    height: 400px;
    width: 100%;
  }

  .fiction-slide-card .detail {
    padding: 15px 15px;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 25% 44.8% 12%;
    gap: 10px;
    padding-left: 60px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 15% 56.8% 12%;
    gap: 10px;
    padding-left: 60px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 60px;
  }

  .picked-titles {
    padding: 44px 0 102px;
    padding-left: 60px;
    width: 84.5%;
  }

  .picked-titles .picked-card.dark img {
    width: 171px;
  }

  .picked-titles .picked-card .picked-title {
    font-size: 110px;
    line-height: 70px;
    padding-bottom: 20px;
    padding-top: 60px;
  }

  .picked-titles .picked-card .detail {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 21px;
  }

  .picked-titles .picked-card .cta {
    padding: 160px 0 0;
  }

  .picked-titles .picked-card {
    border-radius: 10px;
    padding: 18px;
  }

  .becoming-member {
    padding: 229px 0 105px;
    width: 84.5%;
  }

  .becoming-member .content .heading-h2 {
    padding: 24px 0px 46px 0;
    margin-bottom: 21px;
    line-height: 48px !important;
  }

  .web-header {
    padding-top: 30px;
  }

  .h-before-footer .title .heading-h2::after {
    width: 30%;
    right: -45px;
    bottom: 15px;
    height: 2px;
  }

  .h-before-footer .detail {
    margin-bottom: 13px;
  }

  .h-before-footer .title .heading-h2 {
    margin-bottom: 20px;
  }

  .h-before-footer {
    padding: 103px 0 99px;
    width: 84.5%;
  }

  .house-footer {
    padding: 60px 0px 87px 60px;
    border-top: 0;
  }

  .house-footer::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1.5px;
    background: var(--yellow);
    left: 0;
    top: 0;
  }

  .house-footer .footer-top {
    grid-template-columns: 18% 18% 17% 31%;
    gap: 40px;
  }

  .house-footer .reserved {
    gap: 15px;
  }

  .house-footer .footer-bottom {
    grid-template-columns: 60% 40%;
    margin-top: 33px;
  }

  .becoming-member .content {
    max-width: 52%;
  }

  .house-footer .reserved p {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .privacy-policy a {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .f-detail .our-story li {
    margin-bottom: 15px;
  }

  .admin-panel-data.order-list85 .custom-dropdown .selected-option {
    padding: 2px 12px 1px;
    font-size: 13px;
    line-height: 26px;
  }

  .admin-panel-data.order-list85 .custom-dropdown::after {
    right: 15px;
    top: 13px;
    width: 11px;
    height: 8px;
  }

  .admin-panel-data .book-list .title-value .top-value {
    padding-right: 20px;
  }

  .suits89ty {
    width: 80%;
  }
  .admin-panel-data.subscription-list .tb-res-v1 {
    width: 230% !important;
  }

  .admin-panel-data.subscription-list .tb-res-v1 .book-row,
  .admin-panel-data.subscription-list .tb-res-v1 .top-title {
    gap: 10px !important;
    grid-template-columns: 10% 16% 7% 7% 8% 7% 7% 7% 6% 18% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1359px) {
  :root {
    /* style guide for 1440 */
    --h1-font-size: 60px;
    --h1-line-height: 70px;

    --h2-font-size: 36px;
    --h2-line-height: 44px;

    --h3-font-size: 24px;
    --h3-line-height: 30px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 18px;
    --h5-line-height: 30px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 16px;
    --body-line-height: 24px;

    --login-title: 50px;
    --login-line-height: 50px;
  }

  .web-header .social-left .social-top .header-cart .cart-value {
    position: absolute;
    top: -15px;
    right: -3px;
  }

  .admin-panel-data.list_refunds-v1 .top-title,
  .admin-panel-data.list_refunds-v1 .book-row {
    grid-template-columns: 10% 14% 5.5% 5.5% 8% 7% 8% 5% 7% 7.5% 5.5% 12% !important;
    gap: 10px !important;
  }

  .admin-panel-data.list_refunds-v1 .tb-res-v1 {
    width: 230%;
  }

  .custom-dropdown .dropdown-options .option {
    font-size: 13px;
    line-height: 13px;
    font-family: var(--indivisible);
    padding-top: 6px;
    padding: 5px 15px;
  }

  .literary-cart .literary-row .schedule-return {
    margin-bottom: 20px;
  }

  .admin-panel-data.order-list85 .book-list .book-row,
  .admin-panel-data.order-list85 .top-title {
    grid-template-columns: 5% 5% 8% 16% 14% 4.5% 10% 11% 6% 9.5% 8% !important;
    gap: 5px;
  }

  .admin-panel-data.borrowed-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.borrowed-list85 .tb-res-v1 {
    width: 150%;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .admin-panel-main .left .admin-menu-main {
    padding: 20px 10px !important;
    margin-top: 20px;
    max-height: 38vh;
    overflow: auto;
  }

  .admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.order-list85 .tb-res-v1 {
    width: 230%;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .home-hero .title .heading-h1 {
    max-width: 99%;
    font-size: 36px;
  }

  .login-main-wrap {
    padding-top: 294px;
    max-width: 535px;
  }

  .custom-dropdown .selected-option {
    padding: 45px 16px 7px;
    font-size: 18px;
    line-height: 26px;
  }

  .custom-dropdown::after {
    right: 15px;
    top: 56px;
    width: 11px;
    height: 8px;
  }

  .selected-option.category::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.publisher::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.author::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.popular::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .library-overview-hero.detail-v1 .right {
    margin-bottom: 50px;
  }

  .admin-panel-data.subscription-list .book-list .book-row {
    grid-template-columns: 14.5% 15.5% 14% 8% 6% 7% 14% 14%;
    gap: 1%;
  }

  .admin-panel-data.subscription-list .top-title {
    grid-template-columns: 14.5% 15.5% 14% 8% 6% 7% 14% 14%;
    gap: 1%;
  }

  .admin-panel-data.order-list85 .custom-dropdown {
    width: 116px;
  }

  .custom-upload-file .fileUpload.btn.btn--browse {
    padding: 13px 11px;
    font-size: 13px;
  }

  .library-overview-hero.faq::after {
    width: 100%;
    height: 306px;
  }

  .library-overview-hero.terms::after {
    width: 100%;
    height: 306px;
  }

  .home-hero .sub-title .heading-h4 {
    font-size: 18px;
    line-height: 30px;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 240px;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 240px;
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 240px;
  }

  .profile1-main .s-plan {
    width: 72%;
  }

  .profile1-m1 .payment-done80 {
    width: 85%;
  }

  .web-header .social-left .searchdiv {
    right: 16px;
    top: -10px;
    width: 350px;
  }

  .web-header .social-left .show-on-click {
    padding: 10px 20px;
    top: 46px;
    right: 16px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 403.13px;
  }

  /* Admin Panel */

  .admin-panel-main .right .panel-title-top.all-book {
    grid-template-columns: 29% 70%;
    margin-top: 49px;
  }

  .admin-panel-main .panel-row .right {
    width: 79%;
    padding-right: 30px;
  }

  .admin-panel-main .dash-filter.filter .search {
    padding-left: 110px;
  }

  .admin-panel-main .panel-row .left {
    height: 100vh;
    width: 19.5%;
    padding: 30px 20px;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a {
    font-size: 16px;
    padding: 8.5px 3px;
    grid-template-columns: 29px 1fr;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a img {
    width: 18px;
  }

  .admin-panel-main .panel-row .bottom {
    width: 203px;
    bottom: 32px;
    left: 22px;
  }

  .books-pagination {
    margin: 40px 0 30px;
  }

  .admin-panel-main .dash-filter.filter {
    top: 55px;
    grid-template-columns: 60% 40%;
    left: 0;
    padding: 0px 30px 0 0;
  }

  .admin-panel-main .right .panel-title-top {
    grid-template-columns: 30% 70%;
    margin-top: 40px;
  }

  .admin-panel-data .top-title .top-t {
    font-size: 15px;
    line-height: 1.2em;
  }

  .admin-panel-data .top-title {
    grid-template-columns: 5% 13% 23% 11% 12% 6% 8% 7% 7%;
    gap: 1%;
    padding: 55px 16px 10px;
  }

  .admin-panel-data .book-list .book-row {
    grid-template-columns: 5% 13% 23% 11% 12% 6% 8% 7% 7%;
    gap: 1%;
    padding: 8px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .e-status-value .toggle-on {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-status-value .toggle-off {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-edit-delete-value .edit-action img {
    width: 20px;
  }
  /* end */

  /* my account */
  .subscription-main.account .cost-tab {
    grid-template-columns: repeat(auto-fill, 145px);
    width: 100%;
    gap: 10px;
    border: 0px;
  }

  .my-account-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .profile1-m1 .pro1-title {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .order-book-card {
    grid-template-columns: 125px 1fr;
    gap: 30px;
    padding: 20px 0;
  }

  .order-book-card .book-name {
    margin-top: 5px;
  }

  .order-book-card .cta {
    margin-top: 5px;
  }

  .order-book-card .book-name h3 {
    margin-bottom: 0px;
  }

  .profile1-m1 {
    position: relative;
    padding: 30px 0 100px;
  }

  .my-account-hero .ac-title {
    max-width: 70%;
    padding-bottom: 47px;
  }

  .profile1-main {
    padding: 50px 0 130px;
  }

  .profile1-m1 .p1-detail {
    width: 90%;
    padding-bottom: 20px;
  }

  .book-panel-form .pan-form-row {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }

  .add-remove-input .make {
    padding: 0px 27px 2px 7px;
    border-radius: 3px;
    font-size: 11px;
    background-size: 11px;
    background-position: 91% 7px;
  }

  .add-remove-input .remove {
    padding: 2px 30px 2px 6px;
    border-radius: 3px;
    font-size: 13px;
    background-size: 10px;
    background-position: 90% 9px;
  }

  .profile1-m1 .pro1-tab {
    width: 90%;
    gap: 40px;
  }

  .profile1-m1 .current-stack15 {
    width: 90%;
  }

  .order-history .tab-content {
    width: 80%;
    padding: 10px 0;
  }

  .cost-order-tab {
    width: 40%;
  }

  .profile1-m1 .reading-list15 {
    width: 90%;
  }

  .my-account-hero .ac-sub-title {
    padding-top: 250px;
    margin-bottom: 30px;
    padding-right: 60px;
  }

  .subscription-main.account .order-book-card .top-row {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .order-book-card .top-row.previous-v1 {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

  /* end */

  /* our-story */
  .our-story-main {
    width: 84.5%;
    padding: 320px 60px 0;
  }

  .our-story-s1 {
    width: 84.5%;
    padding: 0 60px;
  }

  .our-story-s2 {
    width: 84.5%;
    padding: 100px 60px 40px;
  }

  .our-story-s2 .top-main {
    width: 80%;
    padding-right: 3%;
  }

  .our-story-s1 .r-image .img-1 {
    width: 440px;
    height: 388px;
    border-radius: 15px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-right {
    height: 220px;
  }

  .our-story-main::after {
    height: 90px;
    width: 38.5%;
    border-radius: 0 10px 0 0;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 5%;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 294px;
    width: 100%;
    margin-top: 35px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 28.5% 66%;
    margin-top: 40px;
    gap: 5.5%;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 171px;
    width: 173px;
    border-radius: 15px;
    margin-top: 22px;
    margin-right: 0;
  }

  .our-story-s1 .s1-left .s-content {
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  .our-story-s1 .s1-row .right .r-image {
    margin-top: 100px;
    gap: 18px;
  }

  .our-story-main .blank-bottom {
    height: 130px;
    width: 85%;
    margin-top: 68px;
  }

  .our-story-s1 .s1-row {
    grid-template-columns: 33% 62.7%;
    gap: 4.3%;
  }

  .our-story-main .story-title {
    width: 78%;
    margin-top: 19px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 7px;
    line-height: 1.6em;
  }

  .our-story-s1 .s1-left .s-title {
    max-width: 88%;
    margin-bottom: 18px;
    line-height: 1.2em;
  }

  /* End */

  /* order */

  .literary-cart .top .top-heading {
    font-size: 28px;
    line-height: 1.2em;
    margin-top: 28px;
  }

  .literary-cart .literary-row .l-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .literary-cart .delivery .address {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding-bottom: 12px;
    padding-top: 16px;
  }

  .literary-cart .book-return {
    padding: 13px 0 0;
  }

  .place-order-card .book-ordered {
    padding: 20px 0 0px;
  }

  .place-order-card .book-ordered .ordered {
    padding-bottom: 8px;
  }

  .place-order-card {
    border-radius: 10px;
    padding: 22px 22px 45px;
  }

  .order-page-hero .hero-s-title {
    padding-top: 230px;
  }

  .order-page-hero::after {
    width: 96.5%;
    height: 364px;
    bottom: -1px;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    height: 84px;
    font-size: 18px;
    padding: 43px 15px 5px;
  }

  .place-order-card .place-order {
    bottom: -20px;
    z-index: 999;
    left: 15%;
  }

  #OrderNowMain-tab-OrderNow::after {
    left: 20px;
    top: 16px;
  }

  .literary-cart .delivery {
    padding-right: 30px;
    margin-top: 20px;
  }

  .subscription-main {
    width: 84.5%;
    padding: 0 60px;
  }

  .literary-cart .top .top-heading::after {
    right: 0px;
    top: 19px;
    width: 65%;
    height: 2px;
  }

  .literary-cart .order-book-card {
    grid-template-columns: 120px 1fr;
    gap: 8px;
    padding: 20px 0;
  }

  .literary-cart .literary-row .c-icon img {
    margin-bottom: 12px;
  }

  .literary-cart .literary-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .order-book-card .top-row {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .literary-cart {
    display: grid;
    grid-template-columns: 59% 39%;
    gap: 2%;
    margin-top: 5px;
  }

  .literary-cart .order-book-card .book {
    width: 100px;
    height: 174px;
  }

  .order-book-card .top-row .o-sub-title {
    font-size: 15px;
  }

  /* end */

  .thanku-page-main {
    width: 76%;
    padding-top: 300px;
  }

  /* term */
  .library-overview-hero.terms .overview-subtitle {
    padding-top: 270px;
    padding-bottom: 390px;
    width: 50%;
  }

  .t-and-c-main {
    margin-top: -240px;
    grid-template-columns: 31.5% 65%;
    width: 84.5%;
    padding: 0 60px 40px;
    gap: 3%;
  }

  .t-and-c-main .left-main {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .t-and-c-main .right-content {
    padding-right: 60px;
  }

  /* end */

  .detail.demand-slider {
    width: 84.5%;
    position: relative;
    padding: 0 60px;
    margin-bottom: 50px;
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 394.13px;
  }

  .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
  }

  .custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
  }

  .overview-filter-main .filter-row .left-area {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .custom-dropdown {
    width: 100%;
  }

  .overview-filter-main .filter-row {
    grid-template-columns: 70% 26%;
    gap: 4%;
  }

  .overview-filter-main .overview-top-title {
    grid-template-columns: 70% 26%;
    gap: 4%;
  }

  .fiction-slide-card .detail .book-name {
    padding: 7px 0;
    margin-bottom: 0;
    width: 100%;
    height: 68px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .demand-card .content .detail .book-name {
    padding: 5px 0 0px;
  }

  .overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 60px);
  }

  /* Contact Us */
  .contact-us-main {
    width: 84.5%;
    padding: 0 60px;
    margin-top: 90px;
  }

  .library-overview-hero.contact-us .overview-detail {
    padding-bottom: 75px;
  }

  .contact-us-main .left .address .cta {
    font-size: 17px;
    line-height: 34px;
  }

  .contact-us-main .left .address .location.cta {
    font-size: 17px;
  }

  .contact-us-main .left .address {
    border-radius: 10px;
    padding: 54px 26px 39px;
  }

  .contact-us-main .in-touch .sub-title {
    margin-bottom: 48px;
    margin-top: 6px;
  }

  .contact-us-main .contact-row {
    grid-template-columns: 40.5% 1fr;
    gap: 4.5%;
    margin-bottom: 110px;
  }

  .contact-form .cost-form-group {
    grid-template-columns: 1fr 1fr;
  }

  .contact-form .sign-form-col .w-55 {
    width: 100%;
  }

  .contact-us-main .in-touch .title {
    margin-bottom: 30px;
  }

  .contact-us-main .right {
    padding-right: 8px;
  }

  .library-overview-hero.contact-us::after {
    display: none;
  }

  .contact-us-main .left .inquiries {
    margin-bottom: 24px;
  }

  /* join us */
  .library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 78%;
  }

  .join-questions-main {
    width: 84.5%;
    padding: 12px 60px 80px;
  }

  .library-overview-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .choose-plan-main .plan-row {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 100%;
    row-gap: 60px;
  }

  .join-us-modal .plan-detail {
    padding: 10px 20px;
    margin-top: 10px;
  }

  .library-overview-hero.join-us::after {
    display: none;
  }

  .choose-plan-main {
    width: 84.5%;
    padding: 0 60px;
    margin-top: 60px;
    margin-bottom: 100px;
  }

  .faq-accordion .accordion-button {
    padding: 30px 50px;
    font-size: 18px;
    line-height: 1.3em;
  }

  .join-questions-main .faq-row .q-card-cta {
    margin-top: 22px;
  }

  .faq-accordion .accordion-body {
    padding: 12px 50px 30px;
  }

  .plan-card {
    padding: 35px 55px 50px;
  }

  .join-questions-main .q-card {
    height: 250px;
  }

  .join-questions-main .q-card p {
    font-size: 30px;
    line-height: 1.3em;
    max-width: 44%;
    padding: 61px 0 39px 35px;
  }

  .join-questions-main .faq-row {
    grid-template-columns: 31% 67%;
    gap: 2%;
  }

  /* library detail */
  .product-detail .product {
    width: 374px;
    height: 607px;
  }

  .product-detail {
    grid-template-columns: 45.5% 54.5%;
    padding: 0 60px;
    width: 84.5%;
    padding-top: 80px;
  }

  .product-detail .book-category {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .library-overview-hero.detail::after {
    display: none;
  }

  .product-detail .notify-btn {
    padding: 13px 40px;
    margin-bottom: 20px;
  }

  .product-detail .add-to-cart {
    padding: 13px 40px;
    margin-bottom: 10px;
  }

  .product-detail .add-to-cart:hover {
    padding: 13px 40px;
    margin-bottom: 10px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 40px !important;
  }

  .product-detail .reading-cart {
    color: var(--dark-green);
    padding: 12px 40px;
  }

  .product-detail .add-cart-cta {
    grid-template-columns: 222px 1fr;
    gap: 20px;
  }

  .product-detail .category-list ul {
    column-gap: 23px;
    row-gap: 10px;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .overview-card-row.detail {
    margin-bottom: 60px;
  }

  .overview-card-row.detail .fiction-slide-card .detail {
    padding: 30px 20px 20px;
  }

  .product-detail .book-detail .detail-title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 15px !important;
  }

  .product-detail .book-detail {
    padding: 12px 0 42px;
    margin-top: 20px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 260px;
    grid-template-columns: 46% 54%;
  }

  .you-like-title::after {
    width: 57%;
    height: 2px;
    right: 60px;
    top: 25px;
  }

  /* end */

  /* Library overview */
  .overview-card-row {
    padding-left: 60px;
    padding-right: 60px;
    width: 84.5%;
    grid-template-columns: repeat(3, 31.1%);
    gap: 30px;
  }

  .overview-footer {
    position: relative;
    width: 84.5%;
    padding: 40px 60px 70px;
  }

  .library-overview-hero .overview-detail {
    margin-top: 0px;
    padding-bottom: 140px;
  }

  .library-overview-hero.join-us .overview-detail {
    margin-top: 26px;
    padding-bottom: 86px;
  }

  .overview-filter-main {
    width: 84.5%;
    padding: 20px 60px 10px;
    margin-top: -50px;
  }

  .library-overview-hero .overview-subtitle {
    padding-top: 250px;
    margin-bottom: 0;
  }

  .library-overview-hero .overview-title {
    margin-top: 10px;
  }

  .overview-card-row .fiction-slide-card .card-image {
    height: 490px !important;
    width: 299.05px !important;
  }

  .library-overview-hero::after {
    height: 90px;
    width: 100%;
    left: 0;
    bottom: -2px;
    border-radius: 0;
  }

  .overview-card-row .fiction-slide-card .detail {
    padding: 26px 15px 7px;
  }

  /* end */

  .home-hero .hero-row {
    grid-template-columns: repeat(1, 1fr 330px);
    gap: 0px;
  }

  .header-logo-main .header-logo {
    width: 187px;
    height: 190px;
  }

  .header-logo-main {
    grid-template-columns: repeat(1, 230px 350px);
    gap: 2px;
    padding-left: 60px;
    width: 100%;
  }

  .house-header .new-cta-s1 {
    justify-content: end;
    padding-right: 50px;
  }

  .web-header .left-sidebar .cost-link {
    font-size: 16px;
    line-height: 35px;
    padding-top: 31px;
  }

  .web-header .social-left .top {
    padding-top: 31px;
  }

  .web-header .left-sidebar .link {
    width: 40px;
  }
  .web-header .social-left {
    width: 80px;
  }

  .hero-book-card .card-image {
    height: 495px;
  }

  .hero-book-card .hero-hover {
    height: 495px;
  }

  .hero-book-card .sub-title p {
    font-size: 13px;
    line-height: 21px;
  }

  .hero-book-card .sub-title {
    padding-top: 23px;
    padding-bottom: 5px;
  }

  .home-hero {
    width: 84.5%;
    padding-left: 60px;
  }

  .house_container {
    width: 84%;
    padding-right: 60px;
  }

  .home-hero .hero-left .sub-title {
    padding-top: 440px;
  }

  .home-hero .hero-left .title {
    padding: 18px 0 27px;
  }

  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 537px) !important;
  }

  .hero-book-card .detail {
    padding-bottom: 10px;
  }

  .hero-slider .splide__arrows.splide__arrows--ttb {
    right: -38px;
    bottom: auto;
    top: 3.5%;
  }

  .hero-book-card .card-content {
    padding: 0 20px;
  }

  .h-before-footer .house-row {
    max-width: 68%;
  }

  .home-hero::after {
    height: 80px;
    width: 36.5%;
    border-radius: 0 10px 0 0;
    bottom: -1px;
  }

  .web-header .social-left .header-whatsapp {
    height: 30px;
    width: 30px;
  }

  .home-hero .new-to-library::before {
    width: 85.5%;
    height: 160px;
    border-radius: 10px 10px 0 0;
  }

  .most-in-demand .top-title {
    grid-template-columns: 22% 45.8% 15%;
    gap: 10px;
    padding-left: 60px;
  }

  .demand-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .hero-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .home-hero .hero-slider {
    height: 930px;
  }

  .home-hero .disc p {
    max-width: 76%;
  }

  .home-hero .title .heading-h1 .small {
    font-size: 32px;
    line-height: 55px;
    font-weight: 100 !important;
    top: 11px;
    left: 75%;
  }

  .home-hero .new-to-library .explore {
    margin-right: 105px;
    margin-top: 7px;
  }

  .home-hero .new-to-library .new-to {
    grid-template-columns: 1fr 86px;
    margin-top: 84px;
    margin-right: 20px;
  }

  .home-hero .new-to-library .blank::after {
    width: 40px;
    left: 30%;
    top: 16px;
  }

  .demand-card .card-image {
    width: 330px;
    height: 495px;
    border-radius: 15px;
  }

  .demand-card .hero-hover {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 495px;
    width: 100%;
  }

  .demand-card .content {
    max-width: 330px;
  }

  #splide02-list #splide02-slide01 .demand-card .number {
    left: -4px !important;
    position: absolute;
  }

  .demand-card .number p {
    font-size: 340px;
  }

  .demand-card .number {
    left: -20px;
    top: 210px;
  }

  .demand-card .content .detail {
    padding: 28px 10px 20px;
  }

  .most-in-demand.repeat {
    padding-top: 30px;
  }

  .most-in-demand {
    padding-top: 58px;
  }

  .most-in-demand .top-title .title {
    margin-bottom: -9px;
    line-height: 48px;
  }

  .demand-slider .splide__arrows.splide__arrows--ltr {
    top: -82px;
    gap: 10px;
  }

  .demand-slider {
    position: relative;
    margin-top: 42px;
  }

  .most-in-demand.repeat.fiction {
    margin-top: 53px;
    margin-bottom: 24px;
  }

  .most-in-demand .top-title .blank p {
    width: 96.6%;
    height: 2px;
    margin-bottom: 12px !important;
  }

  .fiction-slide-card .card-image {
    width: 100%;
    height: 492px;
    border-radius: 15px;
  }

  .fiction-slide-card .hero-hover {
    position: absolute;
    top: 5px;
    z-index: 2;
    height: 492px;
    width: 100%;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 25% 43.8% 14%;
    gap: 10px;
    padding-left: 60px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 13% 55.8% 14%;
    gap: 10px;
    padding-left: 60px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 60px;
  }

  .picked-titles {
    padding: 44px 0 102px;
    padding-left: 60px;
    width: 84.5%;
  }

  .picked-titles .picked-card.dark img {
    width: 171px;
  }

  .picked-titles .picked-card .picked-title {
    font-size: 110px;
    line-height: 70px;
    padding-bottom: 20px;
    padding-top: 60px;
  }

  .picked-titles .picked-card .detail {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 21px;
  }

  .picked-titles .picked-card .cta {
    padding: 160px 0 0;
  }

  .picked-titles .picked-card {
    border-radius: 10px;
    padding: 18px;
  }

  .becoming-member {
    padding: 229px 0 90px;
    width: 84.5%;
  }

  .becoming-member .content .heading-h2 {
    padding: 24px 0px 46px 0;
    margin-bottom: 21px;
    line-height: 48px !important;
  }

  .web-header {
    padding-top: 30px;
  }

  .h-before-footer .title .heading-h2::after {
    width: 37%;
    right: -45px;
    bottom: 15px;
    height: 2px;
  }

  .h-before-footer .detail {
    margin-bottom: 13px;
  }

  .h-before-footer .title .heading-h2 {
    margin-bottom: 20px;
  }

  .h-before-footer {
    padding: 103px 0 99px;
    width: 84.5%;
  }

  .house-footer {
    padding: 60px 0px 87px 60px;
    border-top: 0;
  }

  .house-footer::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1.5px;
    background: var(--yellow);
    left: 0;
    top: 0;
  }

  .house-footer .footer-top {
    grid-template-columns: 18% 18% 17% 31%;
    gap: 40px;
  }

  .house-footer .reserved {
    gap: 15px;
  }

  .house-footer .footer-bottom {
    grid-template-columns: 60% 40%;
    margin-top: 33px;
  }

  .becoming-member .content {
    max-width: 52%;
  }

  .house-footer .reserved p {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .privacy-policy a {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .f-detail .our-story li {
    margin-bottom: 15px;
  }

  .admin-panel-data.order-list85 .custom-dropdown .selected-option {
    padding: 2px 12px 1px;
    font-size: 13px;
    line-height: 26px;
  }

  .admin-panel-data.order-list85 .custom-dropdown::after {
    right: 15px;
    top: 13px;
    width: 11px;
    height: 8px;
  }

  .admin-panel-data .book-list .title-value .top-value {
    padding-right: 20px;
  }

  .suits89ty {
    width: 98%;
  }

  .admin-panel-data.subscription-list .tb-res-v1 {
    width: 230% !important;
  }

  .admin-panel-data.subscription-list .tb-res-v1 .book-row,
  .admin-panel-data.subscription-list .tb-res-v1 .top-title {
    gap: 10px !important;
    grid-template-columns: 10% 16% 7% 7% 8% 8% 7% 7% 6% 17% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  :root {
    /* style guide for 1440 */
    --h1-font-size: 60px;
    --h1-line-height: 70px;

    --h2-font-size: 36px;
    --h2-line-height: 44px;

    --h3-font-size: 24px;
    --h3-line-height: 30px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 18px;
    --h5-line-height: 30px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 16px;
    --body-line-height: 24px;

    --login-title: 50px;
    --login-line-height: 50px;
  }

  .admin-panel-data.list_refunds-v1 .top-title,
  .admin-panel-data.list_refunds-v1 .book-row {
    grid-template-columns: 11% 15% 5.5% 5.5% 8% 6% 7% 5% 6% 7% 5% 13% !important;
    gap: 10px !important;
  }

  .admin-panel-data.list_refunds-v1 .tb-res-v1 {
    width: 230%;
  }

  .admin-panel-data.borrowed-list85 .tb-res-v1 {
    width: 160%;
  }

  .literary-cart .literary-row .schedule-return {
    margin-bottom: 20px;
  }

  .admin-panel-data.order-list85 .book-list .book-row,
  .admin-panel-data.order-list85 .top-title {
    grid-template-columns: 5% 5% 8% 16% 14% 4.5% 10% 11% 6% 9.5% 8% !important;
    gap: 5px;
  }

  .admin-panel-data.borrowed-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.borrowed-list85 .tb-res-v1 {
    width: 160%;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.borrowed-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
  }

  .admin-panel-data.order-list85 .tb-res-v1 {
    width: 230%;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }

  .admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

  .overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 60px);
  }

  .library-overview-hero.detail-v1 .right {
    margin-bottom: 60px;
  }

  .admin-search-input input.form-control {
    padding: 4px 0.75rem !important;
  }

  .admin-panel-data.order-list85 .custom-dropdown {
    width: 116px;
  }

  .admin-panel-main .right .panel-title-top .heading-h3 {
    font-size: 18px;
  }

  .accept-reject {
    font-size: 11px;
  }

  .custom-upload-file .fileUpload.btn.btn--browse {
    padding: 15px 8px;
    font-size: 11px;
  }

  .join-questions-main.faq .right {
    width: 100%;
  }

  .library-overview-hero.faq::after {
    display: none;
  }

  .library-overview-hero.faq .overview-title {
    padding-bottom: 60px;
  }

  .join-questions-main.faq {
    margin-top: 30px;
    padding-bottom: 120px;
  }

  .library-overview-hero.terms::after {
    width: 100%;
    height: 306px;
  }

  .home-hero .sub-title .heading-h4 {
    font-size: 16px;
    line-height: 26px;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 220px;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 220px;
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 220px;
  }

  .library-overview-hero.detail .reviews {
    gap: 15px;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .home-hero .title .heading-h1 {
    max-width: 99%;
    font-size: 36px;
  }

  .profile1-main .s-plan {
    width: 80%;
  }

  .profile1-m1 .payment-done80 {
    width: 95%;
  }

  .house-header.fix-header .header-logo-main {
    height: 71px;
  }

  .house-header.fix-header .header-logo-main .header-logo {
    width: 120px;
    height: 35px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 403.13px;
  }

  /* Admin Panel */

  .book-panel-form .pan-form-row {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }

  .add-remove-input .make {
    padding: 0px 27px 2px 7px;
    border-radius: 3px;
    font-size: 11px;
    background-size: 11px;
    background-position: 91% 7px;
  }

  .add-remove-input .remove {
    padding: 2px 30px 2px 6px;
    border-radius: 3px;
    font-size: 13px;
    background-size: 10px;
    background-position: 90% 9px;
  }

  .admin-panel-main .panel-row .right {
    width: 81%;
    padding-right: 20px;
  }

  .admin-panel-main .logo {
    width: 125px;
    height: auto;
  }

  .admin-panel-main .left .panel-title p {
    font-size: 18px;
    line-height: 27px;
    padding: 20px 10px 18px;
  }

  .admin-panel-main .panel-row .left .panel-name {
    display: grid;
    grid-template-columns: 52px 1fr;
    gap: 10px;
    padding: 10px 10px 15px;
  }

  .admin-panel-main .dash-filter.filter .search {
    padding-left: 70px;
  }

  .admin-panel-main .panel-row .left {
    height: 100vh;
    width: 17%;
    padding: 30px 0px;
  }

  .admin-panel-main .left .admin-menu-main {
    padding: 10px 0px !important;
    margin-top: 28px;
    max-height: 43vh;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a {
    font-size: 15px;
    padding: 4px 7px;
    grid-template-columns: 25px 1fr;
  }

  .admin-panel-main .left .admin-menu-main .admin-menu a img {
    width: 18px;
  }

  .admin-panel-main .panel-row .bottom {
    width: 174px;
    bottom: 50px;
    left: 0;
  }

  .admin-panel-main .panel-row .bottom .log-out a {
    padding-left: 15px;
  }

  .admin-panel-main .panel-row .bottom .log-out {
    padding: 12px 0;
  }

  .books-pagination {
    margin: 40px 0 30px;
  }

  .admin-panel-main .dash-filter.filter {
    top: 55px;
    grid-template-columns: 60% 40%;
    left: 0;
    padding: 0px 20px 0 0;
  }

  .admin-panel-main .right .panel-title-top.all-book {
    grid-template-columns: 29% 70%;
    margin-top: 25px;
  }

  .admin-panel-main .right .panel-title-top {
    grid-template-columns: 35% 65%;
    margin-top: 40px;
  }

  .admin-panel-data .top-title .top-t {
    font-size: 13px;
    line-height: 1.2em;
  }

  .admin-panel-data .book-list .top-value {
    font-size: 13px;
  }

  .admin-panel-data .book-list .title-value .top-value {
    font-size: 12px;
    line-height: 1.3em;
    padding-right: 12px;
  }

  .admin-panel-data .top-title {
    grid-template-columns: 6% 12% 23% 11% 11% 7% 8% 7% 7%;
    gap: 1%;
    padding: 22px 16px 10px;
  }

  .admin-panel-data .book-list .book-row {
    grid-template-columns: 6% 12% 23% 11% 11% 7% 8% 7% 7%;
    gap: 1%;
    padding: 8px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .dash-filter.filter .custom-dropdown .selected-option {
    padding: 6px 15px 8px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
  }

  .e-status-value .toggle-on {
    height: 20px;
    width: 30px;
    background-size: 30px;
  }

  .e-status-value .toggle-off {
    height: 30px;
    width: 50px;
    background-size: 35px;
  }

  .e-edit-delete-value .edit-action img {
    width: 17px;
  }

  .e-edit-delete-value .delete-action img {
    width: 17px;
  }

  /* end */

  /* my account */
  .profile1-main .pro1-title {
    margin-bottom: 14px;
    margin-top: 6px;
  }

  .profile1-m1 .form-controla {
    padding: 7px 8px;
    font-style: normal;
  }

  .subscription-main.account .nav-link {
    border-radius: 10px !important;
    height: 68px;
    font-size: 16px;
    padding: 38px 9px 5px;
  }

  .subscription-main.account .order-book-card .top-row {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .order-book-card .top-row.previous-v1 {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

  .subscription-main.account .cost-order-tab .nav-link {
    padding: 7px 15px 5px !important;
    height: 40px !important;
  }

  .subscription-main.account #SubscriptionMain-tab-Subscription::after {
    height: 22.5px;
    width: 25px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account #SubscriptionMain-tab-Payment::after {
    height: 26px;
    width: 28.5px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account #SubscriptionMain-tab-profile::after {
    height: 26px;
    width: 21px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(4) .nav-link:after {
    height: 25px;
    width: 29px;
    left: 10px;
    top: 8px;
  }

  .subscription-main .cost-tab .nav-item:nth-child(5) .nav-link:after {
    height: 23.5px;
    width: 28px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(6) .nav-link:after {
    height: 23.5px;
    width: 28px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab {
    grid-template-columns: repeat(auto-fill, 130px);
    width: 90%;
    gap: 5px;
    border: 0px;
  }

  .my-account-hero {
    width: 93.5%;
    padding-left: 30px;
  }

  .profile1-m1 .pro1-title {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .order-book-card {
    grid-template-columns: 125px 1fr;
    gap: 25px;
    padding: 15px 0;
  }

  .order-book-card .book-name {
    margin-top: 5px;
  }

  .order-book-card .cta {
    margin-top: 5px;
  }

  .order-book-card .book-name h3 {
    margin-bottom: 0px;
  }

  .profile1-m1 {
    position: relative;
    padding: 30px 0 80px;
  }

  .my-account-hero .ac-title {
    max-width: 70%;
    padding-bottom: 47px;
  }

  .profile1-main {
    padding: 50px 0 130px;
  }

  .profile1-m1 .p1-detail {
    width: 90%;
    padding-bottom: 20px;
  }

  .profile1-m1 .pro1-tab {
    width: 90%;
    gap: 40px;
  }

  .profile1-m1 .current-stack15 {
    width: 90%;
  }

  .order-history .tab-content {
    width: 80%;
    padding: 10px 0;
  }

  .cost-order-tab {
    width: 40%;
  }

  .profile1-m1 .reading-list15 {
    width: 90%;
  }

  .my-account-hero .ac-sub-title {
    padding-top: 250px;
    margin-bottom: 30px;
    padding-right: 60px;
  }

  /* end */

  /* our-story */
  .our-story-main {
    width: 93.5%;
    padding: 320px 30px 0;
  }

  .our-story-s1 {
    width: 93.5%;
    padding: 0 30px;
  }

  .our-story-s2 {
    width: 93.5%;
    padding: 100px 30px 30px;
  }

  .our-story-s2 .top-main {
    width: 83%;
    padding-right: 0;
  }

  .our-story-s1 .r-image .img-1 {
    width: 440px;
    height: 388px;
    border-radius: 15px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-right {
    height: 220px;
  }

  .our-story-main::after {
    height: 90px;
    width: 38.5%;
    border-radius: 0 10px 0 0;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 2%;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 294px;
    width: 100%;
    margin-top: 35px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 28.5% 66%;
    margin-top: 40px;
    gap: 5.5%;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 171px;
    width: 173px;
    border-radius: 15px;
    margin-top: 22px;
    margin-right: 0;
  }

  .our-story-s1 .s1-left .s-content {
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  .our-story-s1 .s1-row .right .r-image {
    margin-top: 100px;
    gap: 18px;
  }

  .our-story-main .blank-bottom {
    height: 130px;
    width: 85%;
    margin-top: 68px;
  }

  .our-story-s1 .s1-row {
    grid-template-columns: 33% 62.7%;
    gap: 4.3%;
  }

  .our-story-main .story-title {
    width: 78%;
    margin-top: 19px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 7px;
    line-height: 1.6em;
  }

  .our-story-s1 .s1-left .s-title {
    max-width: 88%;
    margin-bottom: 18px;
    line-height: 1.2em;
  }
  /* End */

  /* order */

  .literary-cart .top .top-heading {
    font-size: 28px;
    line-height: 1.2em;
    margin-top: 28px;
  }

  .literary-cart .literary-row .l-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .literary-cart .delivery .address {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding-bottom: 12px;
    padding-top: 16px;
  }

  .literary-cart .book-return {
    padding: 13px 0 0;
  }

  .place-order-card .book-ordered {
    padding: 20px 0 0px;
  }

  .place-order-card .book-ordered .ordered {
    padding-bottom: 8px;
  }

  .place-order-card {
    border-radius: 10px;
    padding: 22px 22px 45px;
  }

  .order-page-hero .hero-s-title {
    padding-top: 210px;
  }

  .order-page-hero .hero-title {
    width: 63%;
    margin-top: 10px;
    padding-bottom: 439px;
  }

  .subscription-main.order-now-main .tab-content {
    margin-bottom: 60px;
  }

  .order-page-hero::after {
    width: 100.5%;
    height: 364px;
    bottom: -1px;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    height: 84px;
    font-size: 16px;
    padding: 43px 9px 5px;
  }

  .place-order-card .place-order {
    bottom: -20px;
    z-index: 999;
    left: 15%;
  }

  #OrderNowMain-tab-OrderNow::after {
    left: 20px;
    top: 16px;
  }

  .literary-cart .delivery {
    padding-right: 0;
    margin-top: 20px;
  }

  .subscription-main {
    width: 93.5%;
    padding: 0 30px;
  }

  .literary-cart .top .top-heading::after {
    right: 0px;
    top: 19px;
    width: 65%;
    height: 2px;
  }

  .literary-cart .order-book-card {
    grid-template-columns: 120px 1fr;
    gap: 8px;
    padding: 20px 0;
  }

  .literary-cart .literary-row .c-icon img {
    margin-bottom: 12px;
  }

  .literary-cart .literary-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .order-book-card .top-row {
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin-bottom: 10px !important;
  }

  .literary-cart {
    display: grid;
    grid-template-columns: 59% 39%;
    gap: 2%;
    margin-top: 5px;
  }

  .literary-cart .order-book-card .book {
    width: 100px;
    height: 174px;
  }

  .order-book-card .top-row .o-sub-title {
    font-size: 15px;
  }

  .order-page-hero {
    width: 93.5%;
    padding-left: 0px;
  }

  /* end */

  .page-not-found {
    width: 93.5%;
  }

  .thanku-page-main {
    width: 80%;
    padding-top: 360px;
  }

  /* term */
  .library-overview-hero.terms .overview-subtitle {
    padding-top: 250px;
    padding-bottom: 390px;
    width: 50%;
  }

  .t-and-c-main {
    margin-top: -240px;
    grid-template-columns: 31.5% 65%;
    width: 89.5%;
    padding: 0 30px 40px;
    gap: 3%;
  }

  .t-and-c-main .left-main {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .t-and-c-main .right-content {
    padding-right: 30px;
  }

  /* end */

  .sign-in-form-wrap {
    position: relative;
    width: 93.5%;
  }

  .login-main-wrap {
    padding-top: 240px;
    max-width: 530px;
    margin: 0 auto;
  }

  .detail.demand-slider {
    width: 93.5%;
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 394.13px;
  }

  .detail.demand-slider .splide__slide {
    margin-right: 25px !important;
  }

  .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
  }

  .custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
  }

  .overview-filter-main .filter-row .left-area {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .custom-dropdown {
    width: 100%;
  }

  .overview-filter-main .filter-row {
    grid-template-columns: 74% 21%;
    gap: 5%;
  }

  .custom-dropdown .selected-option {
    padding: 45px 16px 7px;
    font-size: 17px;
    line-height: 26px;
  }

  .custom-dropdown::after {
    right: 15px;
    top: 56px;
    width: 11px;
    height: 8px;
  }

  .selected-option.category::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.publisher::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.author::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.popular::after {
    height: 27px;
    width: 27px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .custom-dropdown .dropdown-options .option {
    font-size: 13px;
    line-height: 13px;
    padding-top: 6px;
    padding: 6px 13px;
  }

  .drop-filter-main .drop-input {
    padding: 5px 8px;
    font-size: 16px;
    line-height: 16px;
  }

  .custom-dropdown .dropdown-options .option:last-child {
    padding-bottom: 15px;
  }

  /* Contact Us */
  .contact-us-main {
    width: 90%;
    padding: 0 30px;
    margin-top: -239px;
  }

  .contact-us-main .left .address {
    border-radius: 10px;
    padding: 54px 26px 39px;
  }

  .contact-us-main .in-touch .sub-title {
    margin-bottom: 50px;
    margin-top: 0;
  }

  .contact-us-main .contact-row {
    grid-template-columns: 100%;
    gap: 4.5%;
    margin-bottom: 75px;
  }

  .contact-form .sign-form-col {
    display: block;
  }

  .contact-form .cost-form-group {
    grid-template-columns: 35% 65%;
    align-items: center;
  }

  .contact-form .sign-form-col .w-55 {
    width: 100%;
  }

  .contact-form .sign-form-col .w-45 {
    width: 100%;
  }

  .contact-form .no-grid .cost-form-group {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .sign-form-col .tb-m {
    margin-bottom: 30px !important;
  }

  .contact-form .cost-form-group label.form-label {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .contact-form .login-form-main .cost-form-input {
    margin-bottom: 0;
  }

  .contact-us-main .in-touch .title {
    margin-bottom: 30px;
  }

  .contact-us-main .right {
    padding-right: 8px;
  }

  .library-overview-hero.contact-us::after {
    height: 305px;
    width: 100%;
    bottom: -2px;
  }

  .contact-us-main .left .inquiries {
    margin-bottom: 24px;
  }

  .contact-us-main .left .address .cta {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px;
  }
  .contact-us-main .left .address .location.cta {
    padding-left: 4px;
    font-size: 18px;
    line-height: 26px;
    margin-top: 8px;
  }

  /* join us */
  .choose-plan-main {
    width: 88%;
    padding: 0 30px;
    margin-top: 80px;
    margin-bottom: 50px;
  }

  .library-overview-hero.join-us .overview-detail {
    margin-top: 26px;
    padding-bottom: 86px;
  }

  .library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 78%;
  }

  .join-questions-main {
    width: 93.5%;
    padding: 40px 30px;
  }

  .library-overview-hero {
    width: 93.5%;
    padding-left: 60px;
  }

  .choose-plan-main .plan-row {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 95%;
    row-gap: 60px;
  }

  .join-questions-main .faq-row .q-card-cta {
    margin-top: 30px;
    width: 100%;
  }

  .library-overview-hero.join-us::after {
    display: none;
  }

  .faq-accordion .accordion-button {
    padding: 30px 30px;
    font-size: 20px;
    line-height: 1.2em;
  }

  .faq-accordion .accordion-body {
    padding: 12px 30px 30px;
  }

  .plan-card {
    padding: 30px 35px 40px;
  }

  .join-questions-main .q-card p {
    font-size: 30px;
    line-height: 1.2em;
    max-width: 44%;
    padding: 100px 0 35px 50px;
  }

  .join-questions-main .q-card {
    height: 300px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
  }

  .join-questions-main .faq-row {
    grid-template-columns: 31% 67%;
    gap: 2%;
  }

  /* library detail */
  .product-detail .product {
    width: 312px;
    height: 467px;
  }

  .product-detail {
    grid-template-columns: 40.5% 59.5%;
    padding: 0 30px;
    width: 93.5%;
    padding-top: 80px;
  }

  .library-overview-hero.detail::after {
    display: none;
  }

  .product-detail .notify-btn {
    padding: 13px 26px;
    margin-bottom: 10px;
  }

  .product-detail .add-to-cart {
    padding: 13px 40px;
    margin-bottom: 10px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 40px !important;
  }

  .product-detail .add-to-cart:hover {
    padding: 13px 40px;
    margin-bottom: 20px;
  }

  .product-detail .reading-cart {
    color: var(--dark-green);
    padding: 12px 40px;
  }

  .product-detail .add-cart-cta {
    grid-template-columns: 230px 1fr;
    gap: 30px;
  }

  .product-detail .category-list ul {
    column-gap: 24px;
    row-gap: 10px;
    justify-content: flex-start;
    padding-left: 13px !important;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .you-like-title {
    width: 93%;
    padding-left: 30px;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .overview-card-row.detail {
    margin-bottom: 60px;
  }

  .overview-card-row.detail .fiction-slide-card .detail {
    padding: 30px 20px 20px;
  }

  .product-detail .book-detail .detail-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px !important;
  }

  .product-detail .book-detail {
    padding: 12px 0 42px;
    margin-top: 20px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 260px;
    grid-template-columns: 40.5% 59%;
  }

  .you-like-title::after {
    width: 60%;
    height: 2px;
    right: 30px;
    top: 23px;
  }

  .product-detail .book-category {
    margin-top: 0px;
    margin-bottom: 15px;
    gap: 20px;
  }

  .detail.demand-slider.det852 {
    margin-bottom: 40px;
  }

  .product-detail .casualties {
    margin-top: 20px;
    gap: 37px;
  }
  /* end */

  /* Library overview */
  .fiction-slide-card .detail .book-name {
    padding: 1px 0 5px;
    margin-bottom: 0;
  }

  .library-overview-hero {
    width: 93.2%;
    padding-left: 30px;
  }

  .overview-card-row {
    padding-left: 30px;
    padding-right: 30px;
    width: 93%;
    grid-template-columns: repeat(3, 31.1%);
    gap: 30px;
  }

  .overview-footer {
    position: relative;
    width: 93%;
    padding: 40px 30px 70px;
  }

  .library-overview-hero .overview-detail {
    margin-top: 0px;
    padding-bottom: 110px;
  }

  .overview-filter-main {
    width: 93%;
    padding: 20px 30px 10px;
    margin-top: -50px;
  }

  .library-overview-hero .overview-subtitle {
    padding-top: 250px;
    margin-bottom: 0;
  }

  .library-overview-hero .overview-title {
    margin-top: 10px;
  }

  .overview-card-row .fiction-slide-card .card-image {
    height: 424px !important;
    width: 276.6px !important;
  }

  .library-overview-hero::after {
    height: 80px;
    width: 100%;
    left: 0;
    bottom: -2px;
    border-radius: 0;
  }

  .overview-card-row .fiction-slide-card .detail {
    padding: 20px 15px 7px;
  }
  /* end */

  .demand-card .content .detail .category {
    font-size: 13px;
    line-height: 30px;
  }

  .fiction-slide-card .detail .category {
    font-size: 13px;
    line-height: 30px;
  }

  .home-hero .hero-row {
    grid-template-columns: repeat(1, 1fr 284px);
    gap: 0px;
  }

  .header-logo-main .header-logo {
    width: 152px;
    height: 154px;
  }

  .header-logo-main {
    grid-template-columns: repeat(1, 190px 350px);
    gap: 2px;
    padding-left: 30px;
    width: 100%;
  }

  .house-header .new-cta-s1 {
    display: flex;
    gap: 30px;
    justify-content: end;
    padding-right: 50px;
  }

  .web-header .left-sidebar .cost-link {
    font-size: 16px;
    line-height: 35px;
    padding-top: 31px;
  }

  .web-header .social-left .top {
    padding-top: 0px;
  }

  .web-header .social-left .social-top {
    gap: 45px;
    margin-top: 98px;
  }

  .web-header .left-sidebar .link {
    width: 40px;
  }
  .web-header .social-left {
    width: 70px;
  }

  .hero-book-card .card-image {
    height: 426px;
  }

  .hero-book-card .hero-hover {
    height: 426px;
  }

  .hero-book-card .sub-title p {
    font-size: 13px;
    line-height: 21px;
  }

  .hero-book-card .sub-title {
    padding-top: 23px;
    padding-bottom: 5px;
  }

  .home-hero {
    width: 93.5%;
    padding-left: 30px;
  }

  .web-header .left-sidebar {
    height: 100vh;
    width: 6.6%;
  }

  .house_container {
    width: 93%;
    padding-right: 30px;
  }

  .home-hero .hero-left .sub-title {
    padding-top: 323px;
  }

  .home-hero .hero-left .title {
    padding: 14px 0 26px;
  }

  .most-in-demand .top-title .title {
    line-height: 44px;
  }

  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 260px) !important;
  }

  .hero-book-card .detail {
    position: relative;
    padding-bottom: 15px;
  }

  .web-header .social-left .header-whatsapp {
    height: 30px;
    width: 30px;
  }

  .h-before-footer .house-row {
    max-width: 66%;
  }

  .h-before-footer .house-row .cta {
    margin-right: 31px;
  }

  .home-hero::after {
    height: 80px;
    width: 33%;
    border-radius: 0 10px 0 0;
    bottom: -1px;
  }

  .home-hero .new-to-library::before {
    width: 83.6%;
    height: 160px;
    border-radius: 10px 10px 0 0;
  }

  .hero-slider .splide__arrows.splide__arrows--ttb {
    right: -10px;
    bottom: auto;
    top: 3%;
  }

  .most-in-demand .top-title {
    grid-template-columns: 23% 45.8% 14%;
    gap: 10px;
    padding-left: 30px;
  }

  .demand-slider .splide__arrows.splide__arrows--ltr {
    top: -85px;
    gap: 10px;
  }

  .house-footer .f-detail .our-story {
    padding-left: 0;
    column-count: 1;
    display: block;
  }

  .demand-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .hero-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .home-hero .hero-slider {
    height: 768px;
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: 219px;
  }

  .home-hero .disc p {
    max-width: 85%;
  }

  .home-hero .title .heading-h1 .small {
    font-size: 26px;
    line-height: 55px;
    font-weight: 100 !important;
    top: 8px;
    left: 75%;
  }

  .home-hero .new-to-library .explore {
    margin-right: 100px;
    margin-top: 0px;
    padding-bottom: 55px;
  }

  .home-hero .new-to-library .new-to {
    grid-template-columns: 1fr 90px;
    margin-top: 100px;
    margin-right: 10px;
  }

  .home-hero .new-to-library .blank::after {
    width: 40px;
    left: 30%;
    top: 16px;
  }

  .demand-card .card-image {
    width: 284px;
    height: 426px;
  }

  .demand-card .hero-hover {
    z-index: 2;
    height: 426px;
    width: 284px;
  }
  .fiction-slide-card .hero-hover {
    position: absolute;
    top: 7px;
    z-index: 2;
    height: 426px;
    width: 284px;
  }

  .overview-card-row .fiction-slide-card .hero-hover {
    height: 425px !important;
    width: 276.6px !important;
  }

  .demand-card .content {
    max-width: 289px;
  }

  #splide02-list #splide02-slide01 .demand-card .number {
    left: -3px !important;
    position: absolute;
  }

  .demand-card .content .detail .book-name {
    height: 66px;
  }

  .demand-card .number p {
    font-size: 300px;
  }

  .demand-card .number {
    left: -20px;
    top: 210px;
  }

  .demand-card .content .detail {
    padding: 28px 10px 20px;
  }

  .most-in-demand.repeat {
    padding-top: 30px;
  }

  .most-in-demand {
    padding-top: 68px;
  }

  .most-in-demand .top-title .blank p {
    width: 96.6%;
    height: 2px;
    margin-bottom: 14px !important;
  }

  .most-in-demand.repeat.fiction {
    margin-top: 4px;
    margin-bottom: 0px;
  }

  .demand-slider {
    margin-top: 44px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 15% 51.8% 14.2%;
    gap: 10px;
  }

  .fiction-slide-card .card-image {
    width: 100%;
    height: 426px;
  }

  .demand-slider .splide__slide {
    margin-right: 30px !important;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 24% 43.8% 14%;
    gap: 10px;
    padding-left: 30px;
  }

  .most-in-demand .top-title .cta .cost-btn {
    margin-left: auto;
    margin-bottom: 3px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 30px;
  }

  .picked-titles {
    padding: 85px 0 90px;
    padding-left: 30px;
    width: 93%;
  }

  .picked-titles .picked-card.dark img {
    width: 160px;
  }

  .picked-titles .picked-card .picked-title {
    font-size: 90px;
    line-height: 70px;
    padding-bottom: 10px;
    padding-top: 70px;
  }

  .picked-titles .picked-card .detail {
    font-size: 22px;
    line-height: 34px;
  }

  .picked-titles .picked-card .cta {
    padding: 137px 0 0;
  }

  .picked-titles .picked-card {
    border-radius: 10px;
    padding: 24px;
  }

  .becoming-member {
    padding: 220px 0 90px;
    width: 93.5%;
  }

  .becoming-member .content .heading-h2 {
    padding: 25px 0px 46px 0;
    margin-bottom: 21px;
    line-height: 48px !important;
  }

  .web-header {
    padding-top: 30px;
  }

  .web-header .cost-navbar {
    display: none;
  }

  .mob.mobile-menu-main {
    display: block;
    width: max-content;
    right: 0;
    top: 0;
  }

  .h-before-footer .title .heading-h2::after {
    width: 28%;
    right: -47px;
    bottom: 7px;
    height: 2px;
  }

  .h-before-footer .detail {
    margin-bottom: 21px;
  }

  .h-before-footer .title .heading-h2 {
    margin-bottom: 20px;
  }

  .h-before-footer {
    padding: 87px 0 84px;
    width: 93%;
  }

  .house-footer {
    padding: 60px 0px 87px 30px;
    border-top: 0;
  }

  .house-footer::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1.5px;
    background: var(--yellow);
    left: 0;
    top: 0;
  }

  .house-footer .footer-top {
    grid-template-columns: 21% 21% 20% 23%;
    gap: 42px;
  }

  .house-footer .reserved {
    display: block;
  }

  .house-footer .reserved p:first-child {
    line-height: 35px;
  }

  .house-footer .footer-bottom {
    grid-template-columns: 50% 50%;
    margin-top: 33px;
  }

  .becoming-member .content {
    max-width: 55%;
  }

  .house-footer .reserved p {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .privacy-policy a {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .f-detail .our-story li {
    margin-bottom: 9px;
  }

  .join-us-modal .add-new .top {
    padding: 5px 10px;
    margin-bottom: 15px;
  }

  .join-us-modal .plan-detail {
    padding: 7px 15px;
    margin-top: 8px;
    border-radius: 4px;
  }

  .join-us-modal .choose-cards .top1 p {
    font-size: 18px;
    margin-top: 20px;
  }

  .join-us-modal .cost-form-group .form-label {
    margin-bottom: 10px !important;
    font-size: 16px;
  }

  .web-header .social-left .searchdiv {
    right: 14px;
    top: -10px;
    width: 350px;
  }

  .web-header .social-left .show-on-click {
    padding: 10px 20px;
    top: 46px;
    right: 14px;
  }

  .admin-panel-data.order-list85 .custom-dropdown .selected-option {
    padding: 2px 12px 1px;
    font-size: 13px;
    line-height: 26px;
  }

  .admin-panel-data.order-list85 .custom-dropdown::after {
    right: 15px;
    top: 13px;
    width: 11px;
    height: 8px;
  }

  .suits89ty {
    width: 90%;
  }

  .admin-panel-data.subscription-list .tb-res-v1 {
    width: 230% !important;
  }

  .admin-panel-data.subscription-list .tb-res-v1 .book-row,
  .admin-panel-data.subscription-list .tb-res-v1 .top-title {
    gap: 10px !important;
    grid-template-columns: 10% 16% 7% 7% 8% 8% 6% 6% 6% 18% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  :root {
    /* style guide for 1440 */
    --h1-font-size: 50px;
    --h1-line-height: 60px;

    --h2-font-size: 32px;
    --h2-line-height: 40px;

    --h3-font-size: 22px;
    --h3-line-height: 28px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 18px;
    --h5-line-height: 30px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 16px;
    --body-line-height: 24px;

    --login-title: 50px;
    --login-line-height: 50px;
  }

  .order-book-card .order-n.new15 {
    grid-column: 1 / span 3;
    gap: 7px;
    margin-top: 20px;
  }

  .literary-cart .literary-row .l-row .schedule-return-mob {
    grid-column: 1 / 3;
    margin-bottom: 13px;
  }

  .cust-delivery-time select {
    border: 1px solid var(--dark-green);
  }

  .overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 60px);
  }

  .library-overview-hero.detail-v1 .right {
    margin-bottom: 260px;
  }

  .home-hero #splide02-track {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .library-overview-hero.faq::after {
    width: 100%;
    height: 306px;
  }

  .join-questions-main.faq .right {
    width: 100%;
  }

  .home-hero .sub-title .heading-h4 {
    font-size: 16px;
    line-height: 26px;
  }

  .house-header.fix-header .header-logo-main {
    height: 70px;
  }

  .house-header.fix-header .header-logo-main .header-logo {
    width: 125px;
    height: 35px;
  }

  .house-header.fix-header .header-logo-main {
    height: 71px;
    width: 100%;
  }

  .house-header.fix-header .header-logo-main .header-logo {
    width: 123px;
    height: 36px;
  }

  .hero-slider #splide01-track {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .demand-card .content .detail .book-name {
    height: 65px;
  }

  .overview-clear-filter {
    padding-right: 0px;
    display: grid;
    grid-template-columns: 79% 16%;
    gap: 5%;
    margin-bottom: 15px;
  }

  .profile1-main .s-plan {
    width: 100%;
  }

  .profile1-m1 .payment-done80 {
    width: 100%;
  }

  .web-header .social-left .searchdiv {
    right: 14px;
    top: -10px;
    width: 350px;
  }

  .web-header .social-left .show-on-click {
    padding: 10px 20px;
    top: 46px;
    right: 14px;
  }

  .hero-book-card .title {
    padding-bottom: 8px;
    margin-top: 3px;
    max-width: 100%;
    height: 56px;
  }

  .sign-form-main.sign-modal {
    max-width: 100%;
    padding: 20px 0 30px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 403.13px;
    width: 100%;
  }

  /* my account */

  .profile1-main .pro1-title {
    margin-bottom: 14px;
    margin-top: 6px;
  }

  .profile1-m1 .form-controla {
    padding: 7px 8px;
    font-style: normal;
  }

  .subscription-main.account .nav-link {
    border-radius: 10px !important;
    height: 68px;
    font-size: 13px;
    padding: 38px 9px 5px;
  }

  .subscription-main.account .order-book-card .top-row {
    display: block;
  }

  .cost-order-tab .nav-link {
    padding: 7px 15px 5px !important;
    height: 40px !important;
  }

  .subscription-main.account #SubscriptionMain-tab-Subscription::after {
    height: 22.5px;
    width: 25px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account #SubscriptionMain-tab-Payment::after {
    height: 26px;
    width: 28.5px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account #SubscriptionMain-tab-profile::after {
    height: 26px;
    width: 21px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(4) .nav-link:after {
    height: 25px;
    width: 29px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(5) .nav-link:after {
    height: 23.5px;
    width: 28px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(6) .nav-link:after {
    height: 23.5px;
    width: 28px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-order-tab.nav.nav-tabs.nav-fill {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 85%;
  }

  .subscription-main.account .order-history .tab-content {
    width: 100%;
    padding: 10px 0;
  }

  .subscription-main.account .cost-tab {
    grid-template-columns: repeat(auto-fill, 100px);
    width: 100%;
    gap: 5px;
    border: 0px;
  }

  .my-account-hero {
    width: 91%;
    padding-left: 30px;
  }

  .profile1-m1 .pro1-title {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .order-book-card {
    grid-template-columns: 136px 1fr;
    gap: 25px;
    padding: 15px 0;
    align-items: start;
  }

  .order-book-card .book-name {
    margin-top: 5px;
  }

  .order-book-card .cta {
    margin-top: 5px;
  }

  .order-book-card .book-name h3 {
    margin-bottom: 0px;
  }

  .profile1-m1 {
    position: relative;
    padding: 30px 0 80px;
  }

  .my-account-hero .ac-title {
    max-width: 100%;
    padding-bottom: 47px;
  }

  .profile1-main {
    padding: 50px 0 130px;
  }

  .profile1-m1 .p1-detail {
    width: 100%;
    padding-bottom: 20px;
  }

  .profile1-m1 .pro1-tab {
    width: 100%;
    gap: 10px;
  }

  .profile1-m1 .current-stack15 {
    width: 90%;
  }

  .order-history .tab-content {
    width: 68%;
    padding: 10px 0;
  }

  .cost-order-tab {
    width: 40%;
  }

  .profile1-m1 .reading-list15 {
    width: 90%;
  }

  .my-account-hero .ac-sub-title {
    padding-top: 230px;
    margin-bottom: 25px;
    padding-right: 30px;
  }

  /* end */

  /* our-story */

  .our-story-main {
    width: 91%;
    padding: 235px 30px 0;
  }

  .our-story-s1 {
    width: 91%;
    padding: 0 30px;
    margin-top: 80px;
  }

  .our-story-s2 {
    width: 91%;
    padding: 75px 30px 30px;
  }

  .our-story-s2 .top-main {
    width: 100%;
  }

  .our-story-s1 .r-image .img-1 {
    width: 440px;
    height: 388px;
    border-radius: 15px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-right {
    height: max-content;
    padding-bottom: 23px;
  }

  .our-story-s2 .row-s2 .left-s2 {
    order: 2;
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .our-story-main::after {
    height: 55px;
    width: 84.5%;
    border-radius: 0 10px 0 0;
    margin-bottom: -2px;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 0;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 330px;
    width: 100%;
    margin-top: 0px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 100%;
    margin-top: 28px;
    gap: 5.5%;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 230px;
    width: 230px;
    border-radius: 15px;
    margin-top: 0;
  }

  .our-story-s1 .s1-left .s-content {
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  .our-story-s1 .s1-row .right .r-image {
    margin-top: 20px;
    gap: 18px;
  }

  .our-story-main .blank-bottom {
    height: 90px;
    width: 100%;
    margin-top: 67px;
  }

  .our-story-s1 .s1-row {
    grid-template-columns: 100%;
    gap: 4.3%;
  }

  .our-story-main .story-title {
    width: 100%;
    margin-top: 19px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 7px;
    line-height: 1.6em;
  }

  .our-story-s1 .s1-left .s-title {
    max-width: 88%;
    margin-bottom: 18px;
    line-height: 1.2em;
  }

  .our-story-s1 .s1-left {
    padding-top: 0px;
  }

  /* End */

  /* order */

  ul.cost-order-tab.nav.nav-tabs.nav-fill {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .literary-cart {
    display: grid;
    grid-template-columns: 100%;
    gap: 2%;
    margin-top: 5px;
  }

  .literary-cart .literary {
    display: none;
  }

  .literary-cart .delivery {
    display: none;
  }

  .literary-cart.mobile {
    display: block;
  }

  .literary-cart.mobile .literary {
    display: block !important;
  }

  .literary-cart.mobile .delivery {
    display: block !important;
  }

  .literary-cart .top .top-heading {
    font-size: 28px;
    line-height: 1.2em;
    margin-top: 28px;
  }

  .literary-cart .literary-row .l-row {
    grid-template-columns: 56px 1fr;
    max-height: 700px;
    padding-right: 0;
  }

  .literary-cart .delivery .address {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding-bottom: 12px;
    padding-top: 16px;
  }

  .literary-cart .book-return {
    padding: 13px 0 0;
  }

  .place-order-card .book-ordered {
    padding: 20px 0 0px;
  }

  .place-order-card .book-ordered .ordered {
    padding-bottom: 8px;
    color: var(--green);
  }

  .place-order-card {
    border-radius: 0;
    padding: 0;
    background: transparent;
  }

  .literary-cart .delivery .r-side {
    color: var(--green);
  }

  .order-page-hero .hero-s-title {
    padding-top: 210px;
  }

  .order-page-hero .hero-title {
    width: 85%;
    margin-top: 10px;
    padding-bottom: 439px;
  }

  .subscription-main.order-now-main .tab-content {
    margin-bottom: 60px;
  }

  .order-page-hero::after {
    width: 100.5%;
    height: 364px;
    bottom: -1px;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    height: 84px;
    font-size: 18px;
    padding: 43px 15px 5px;
  }

  .place-order-card .place-order {
    bottom: auto;
    z-index: 999;
    left: 0;
    width: 100%;
    position: relative;
  }

  .place-order-card .place-order button.login-button.t-btn {
    width: 100%;
  }

  #OrderNowMain-tab-OrderNow::after {
    left: 20px;
    top: 16px;
  }

  .literary-cart.mobile .previous-step {
    width: 100%;
    margin-top: 50px;
  }

  .literary-cart.mobile .previous-step button {
    margin: 0 auto;
  }

  .literary-cart.mobile .previous-step button.t-btn::after {
    bottom: 0;
    right: 0;
    left: auto;
  }

  .literary-cart.mobile .previous-step button.cost-btn span {
    transform: rotate(181deg);
  }

  .literary-cart .delivery {
    padding-right: 0;
    margin-top: 20px;
  }

  .subscription-main {
    width: 91%;
    padding: 0 30px;
  }

  .literary-cart .top .top-heading::after {
    right: 0px;
    top: 19px;
    width: 65%;
    height: 2px;
  }

  .literary-cart .order-book-card {
    grid-template-columns: 120px 1fr;
    gap: 8px;
    padding: 20px 0;
  }

  .literary-cart .literary-row .c-icon img {
    margin-bottom: 12px;
  }

  .literary-cart .literary-row {
    grid-template-columns: 40px 1fr;
    max-height: 700px;
    padding-right: 15px;
  }

  .order-book-card .top-row {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .literary-cart .order-book-card .book {
    width: 100px;
    height: 174px;
  }

  .order-book-card .top-row .o-sub-title {
    font-size: 15px;
  }

  .order-page-hero {
    width: 91%;
    padding-left: 0px;
  }

  /* end */

  .page-not-found {
    position: relative;
    width: 90.5%;
  }

  .thanku-page-main {
    width: 90%;
    padding-top: 360px;
  }

  /* term */

  .t-and-c-main .left .term-mobile {
    display: block;
  }

  .t-and-c-main .left-main {
    display: none;
  }

  .term-mobile .dropdown-toggle {
    background: transparent;
    border: 1px solid green;
    width: 100%;
    color: var(--green);
    text-align: left;
  }

  .term-mobile .dropdown-menu.show {
    width: 100%;
    position: absolute;
    margin-top: -4px;
    border-radius: 0 0 5px 5px;
  }

  .library-overview-hero.terms .overview-subtitle {
    padding-top: 250px;
    padding-bottom: 390px;
    width: 94%;
  }

  .library-overview-hero.terms::after {
    width: 100%;
    height: 306px;
  }

  .t-and-c-main {
    margin-top: -240px;
    grid-template-columns: 100%;
    width: 89.5%;
    padding: 0 30px 40px;
    gap: 20px;
  }

  .t-and-c-main .left-main {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .t-and-c-main .right-content {
    padding-right: 0px;
  }
  /* end */

  .web-header .social-left .header-whatsapp {
    height: 30px;
    width: 30px;
  }

  /* start */
  .sign-in-form-wrap {
    position: relative;
    width: 90.5%;
  }

  .login-main-wrap {
    padding-top: 240px;
    max-width: 530px;
    margin: 0 auto;
  }

  .sign-form-main.sign {
    max-width: 82%;
  }

  .sign-form-main.sign .cost-form-input.w-55 {
    width: 100%;
  }

  .sign-form-main.sign .cost-form-input.w-45 {
    width: 100%;
  }

  .sign-form-main.sign .cost-form-input.w-80 {
    width: 100%;
  }

  .login-form-main .cost-form-input.w-45.c-mail-w {
    margin-top: -20px;
  }

  .login-form-main .cost-form-input.w-45.c-mail-w1 {
    margin-top: -20px;
  }

  .login-form-main .cost-form-group label.form-label {
    margin-bottom: 0px;
  }

  .login-form-main .cost-form-group {
    margin-bottom: 20px;
  }

  .sign-form-main.sign859a .cost-form-group {
    grid-template-columns: 42% 58%;
  }

  .sign-form-main.sign859a .w-50 {
    width: 65% !important;
  }

  .sign-form-col.sign859a .cost-form-group {
    grid-template-columns: 41% 59%;
  }

  .login-form-main .submit-cta {
    padding-top: 30px;
  }

  /* end */

  .detail.demand-slider {
    width: 94%;
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 394.13px;
  }

  .detail.demand-slider .splide__slide {
    margin-right: 25px !important;
  }

  .overview-filter-main .filter-mobile {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .overview-filter-main .overview-top-title {
    display: none;
  }

  .overview-filter-main .filter-row {
    display: none;
  }

  .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
  }

  .custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
  }

  .overview-filter-main .filter-mobile input[type="button"] {
    border: 1px solid var(--green);
    border-radius: 10px;
    background: var(--bg-color);
    font-size: 15px;
    color: var(--dark-green);
    text-transform: uppercase;
    font-weight: 500;
    padding: 18px 20px;
    width: 300px;
    text-align: left;
    position: relative;
    background-image: url(/public/assets/image/select-dropdown.svg);
    background-repeat: no-repeat;
    background-position: 96%;
  }

  .filter-mobile #divMsg {
    top: 34px;
    width: 93%;
    left: 20px;
    border-radius: 10px;
    z-index: 2;
    padding: 5px 20px 20px;
  }

  .overview-filter-main .filter-row .left-area {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 6px;
  }

  .custom-dropdown {
    width: 300px;
  }

  .overview-filter-main .filter-mobile .right-mobile {
    margin-left: auto;
  }

  .custom-dropdown .selected-option {
    padding: 55px 12px 12px;
    font-size: 16px;
    line-height: 22px;
  }

  .custom-dropdown .dropdown-options .option {
    font-size: 16px;
    line-height: 22px;
    padding-top: 5px;
    padding: 5px 15px;
  }

  .custom-dropdown::after {
    right: 15px;
    top: 65px;
    width: 11px;
    height: 8px;
  }

  .selected-option.category::after {
    height: 30px;
    width: 30px;
    background-size: cover;
    top: 15px;
    left: 15px;
  }

  .selected-option.publisher::after {
    background-size: cover;
    height: 30px;
    width: 30px;
    top: 15px;
    left: 15px;
  }

  .selected-option.genre::after {
    background-size: cover;
    height: 30px;
    width: 30px;
    top: 15px;
    left: 15px;
  }

  .selected-option.author::after {
    background-size: cover;
    height: 30px;
    width: 30px;
    top: 15px;
    left: 15px;
  }

  .selected-option.popular::after {
    background-size: cover;
    height: 30px;
    width: 30px;
    top: 15px;
    left: 15px;
  }

  .custom-dropdown .dropdown-options .option:last-child {
    padding-bottom: 15px;
  }

  /* Contact Us */
  .contact-us-main {
    width: 90%;
    padding: 0 30px;
    margin-top: -239px;
  }

  .contact-us-main .left .address {
    border-radius: 10px;
    padding: 54px 26px 39px;
  }

  .contact-us-main .in-touch .sub-title {
    margin-bottom: 10px;
    margin-top: 35px;
  }

  .form-controla {
    padding: 6px 5px;
  }

  .contact-us-main .contact-row {
    grid-template-columns: 100%;
    gap: 4.5%;
    margin-bottom: 110px;
  }

  .contact-form .sign-form-col {
    display: block;
  }

  .contact-form .cost-form-group {
    grid-template-columns: 35% 65%;
    align-items: center;
  }

  .contact-form .sign-form-col .w-55 {
    width: 100%;
  }

  .contact-form .sign-form-col .w-45 {
    width: 100%;
  }

  .contact-form .no-grid .cost-form-group {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .sign-form-col .tb-m {
    margin-bottom: 30px !important;
  }

  .contact-form .cost-form-group label.form-label {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .contact-form .login-form-main .cost-form-input {
    margin-bottom: 0;
  }

  .contact-us-main .in-touch .title {
    margin-bottom: 30px;
  }

  .contact-us-main .right {
    padding-right: 8px;
  }

  .library-overview-hero.contact-us::after {
    height: 305px;
    width: 100%;
    bottom: -2px;
    border-radius: 0;
  }

  .contact-us-main .left .inquiries {
    margin-bottom: 24px;
  }

  .contact-us-main .left .address .cta {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .contact-us-main .left .address .location.cta {
    padding-left: 4px;
    font-size: 18px;
    line-height: 26px;
    margin-top: 8px;
  }

  /* join us */
  .choose-plan-main {
    width: 90%;
    padding: 0 30px;
    margin-top: 80px;
    margin-bottom: 50px;
  }

  .library-overview-hero.join-us .overview-detail {
    margin-top: 26px;
    padding-bottom: 86px;
  }

  .common-radio .select-radio label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    height: 100%;
    width: 100%;
    max-width: 85%;
  }

  .plan-card .plan-price .old-price {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
  }

  .library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 100%;
    font-size: 60px;
  }

  .plan-card .plan-price .new-price {
    font-size: 15px;
  }

  .join-questions-main {
    width: 93.5%;
    padding: 40px 30px;
  }

  .library-overview-hero {
    width: 93.5%;
    padding-left: 60px;
  }

  .choose-plan-main .plan-row {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 95%;
    row-gap: 60px;
  }

  .join-questions-main .faq-row .q-card-cta {
    margin-top: 19px;
    width: 100%;
  }

  .join-questions-main {
    width: 91.5%;
    padding: 30px 30px;
  }

  .library-overview-hero.join-us::after {
    display: none;
  }

  .faq-accordion .accordion-button {
    padding: 30px 30px;
    font-size: 18px;
    line-height: 24px;
  }

  .faq-accordion .accordion-body {
    padding: 12px 30px 30px;
  }

  .plan-card {
    padding: 20px 10px 21px;
  }

  .join-questions-main .q-card p {
    font-size: 20px;
    line-height: 1.2em;
    max-width: 45%;
    padding: 70px 8px 19px 27px;
  }

  .join-questions-main .q-card {
    height: 200px;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
  }

  .join-questions-main .faq-row {
    grid-template-columns: 31% 67%;
    gap: 2%;
  }

  .login-button {
    padding: 10px 20px;
  }

  .plan-card .card-detail {
    padding-bottom: 10px;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  /* library detail */
  .product-detail .product {
    width: 332px;
    height: 499px;
    margin: 0 auto;
  }

  .product-detail {
    grid-template-columns: 100%;
    padding: 0 30px;
    width: 91%;
    margin-top: -190px;
  }

  .library-overview-hero.detail::after {
    display: none;
  }

  .library-overview-hero.detail .login-title {
    text-align: center;
    margin-top: 43px;
  }

  .library-overview-hero.detail .overview-detail {
    padding-bottom: 0px;
    margin-top: 13px;
    text-align: center;
  }

  .product-detail .notify-btn {
    padding: 13px 20px;
    margin-bottom: 10px;
  }

  .product-detail .add-to-cart {
    padding: 13px 40px;
    margin-bottom: 10px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 40px !important;
  }

  .product-detail .add-to-cart:hover {
    padding: 13px 40px;
    margin-bottom: 20px;
  }

  .product-detail .reading-cart {
    color: var(--dark-green);
    padding: 12px 40px;
  }

  .product-detail .add-cart-cta {
    grid-template-columns: 230px 1fr;
    gap: 30px;
  }

  .product-detail .category-list ul {
    column-gap: 23px;
    row-gap: 10px;
    justify-content: flex-start;
    padding-left: 13px !important;
    margin-top: 0px;
    margin-left: 0;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .you-like-title {
    width: 91%;
    padding-left: 30px;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .overview-card-row.detail {
    margin-bottom: 60px;
  }

  .overview-card-row.detail .fiction-slide-card .detail {
    padding: 30px 20px 20px;
  }

  .product-detail .book-detail .detail-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px !important;
  }

  .product-detail .book-detail {
    padding: 12px 0 42px;
    margin-top: 20px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 260px;
    grid-template-columns: 100%;
  }

  .you-like-title::after {
    width: 50%;
    height: 2px;
    right: 50px;
    top: 23px;
  }

  .product-detail .book-category {
    display: flex;
    margin-top: 56px;
    margin-bottom: 15px;
    gap: 20px;
  }

  .product-detail .casualties {
    margin-top: 20px;
    gap: 37px;
  }

  .library-overview-hero.detail .book-detail-con .left {
    padding-top: 8px;
  }

  .library-overview-hero.detail .reviews {
    gap: 15px;
    padding-bottom: 0px;
    margin-top: 0px;
    justify-content: center;
  }

  /* end */

  /* Library overview */
  .fiction-slide-card .detail .book-name {
    padding: 1px 0 5px;
    margin-bottom: 0;
  }

  .library-overview-hero {
    width: 91%;
    padding-left: 30px;
  }

  .overview-card-row {
    padding-left: 30px;
    padding-right: 30px;
    width: 91%;
    grid-template-columns: repeat(2, 47.5%);
    gap: 30px;
    margin-top: 100px;
  }

  .overview-card-row .fiction-slide-card .hero-hover {
    height: 471px !important;
    width: 303.45px !important;
  }

  .overview-footer {
    position: relative;
    width: 91%;
    padding: 40px 30px 70px;
  }

  .library-overview-hero .overview-detail {
    margin-top: 0px;
    padding-bottom: 110px;
  }

  .overview-filter-main {
    width: 91%;
    padding: 20px 30px 50px;
    margin-top: -75px;
  }

  .library-overview-hero .overview-subtitle {
    padding-top: 250px;
    margin-bottom: 0;
  }

  .library-overview-hero .overview-title {
    margin-top: 10px;
  }

  .overview-card-row .fiction-slide-card .card-image {
    height: 469px !important;
    width: 303.45px !important;
  }

  .library-overview-hero::after {
    height: 80px;
    width: 100%;
    left: 0;
    bottom: -2px;
    border-radius: 0;
  }

  .overview-card-row .fiction-slide-card .detail {
    padding: 20px 15px 7px;
  }

  /* end */

  .picked-titles .picked .pic-cell:last-child {
    display: none;
  }

  .picked-titles .picked {
    grid-template-columns: 1fr 1fr;
  }

  .picked-titles .border-right {
    border-right: 0px;
    border-radius: 0px;
  }

  .home-hero .hero-left .sub-title .heading-h4 {
    font-size: 16px;
  }

  .demand-card .content .detail .category {
    font-size: 13px;
    line-height: 30px;
  }

  .fiction-slide-card .detail .category {
    font-size: 13px;
    line-height: 30px;
  }

  .home-hero .hero-row {
    width: 100%;
    display: block;
  }

  .home-hero .title .heading-h1 {
    max-width: 100%;
  }

  .header-logo-main .header-logo {
    width: 152px;
    height: 154px;
  }

  .header-logo-main {
    grid-template-columns: repeat(1, 200px 400px);
    gap: 2px;
    padding-left: 30px;
    width: 100%;
  }

  .house-header .new-cta-s1 {
    display: flex;
    gap: 0px;
  }

  .header-logo-main .header-btn-y.t-btn.n-o-mobile {
    margin-left: auto;
  }

  .web-header .left-sidebar .cost-link {
    font-size: 16px;
    line-height: 35px;
    padding-top: 31px;
  }

  .web-header .social-left .top {
    padding-top: 0px;
  }

  .web-header .social-left .social-top {
    gap: 45px;
    margin-top: 98px;
  }

  .web-header .left-sidebar .link {
    width: 40px;
  }

  .web-header .social-left {
    width: 70px;
  }

  .hero-book-card .card-image {
    height: 370px;
    width: 100%;
  }

  .hero-book-card .hero-hover {
    height: 372px;
    width: 100%;
  }

  .hero-book-card .sub-title p {
    font-size: 13px;
    line-height: 21px;
  }

  .hero-book-card .sub-title {
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .hero-book-card .card-content {
    position: relative;
    padding: 0 11px;
  }

  .home-hero {
    width: 91%;
    padding-left: 0;
  }

  .home-hero .hero-row .hero-left {
    padding-left: 30px;
  }

  .web-header .left-sidebar {
    height: 100vh;
    width: 9%;
  }

  .house_container {
    width: 90.5%;
    padding-right: 32px;
  }

  .picked-titles .border-right .mobile {
    display: block;
    margin: 0 auto;
  }

  .home-hero .hero-left .sub-title {
    padding-top: 329px;
  }

  .home-hero .hero-left .title {
    padding: 15px 0 21px;
    text-align: center;
  }

  .home-hero .sub-title .heading-h4 {
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
  }

  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 142px) !important;
  }

  .picked-titles .picked-card .detail {
    font-size: 22px;
    line-height: 34px;
    padding-bottom: 32px;
  }

  .home-hero::after {
    height: 71px;
    width: 33%;
    border-radius: 0 10px 0 0;
    bottom: -1px;
  }

  .home-hero .new-to-library::before {
    width: 105.5%;
    height: 347px;
    border-radius: 0;
    bottom: -30px;
  }

  .most-in-demand .top-title {
    grid-template-columns: 29% 25.8% 20%;
    gap: 10px;
    padding-left: 30px;
  }

  .house-footer .f-detail .our-story {
    padding-left: 0;
    column-count: 1;
  }

  .demand-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .hero-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .home-hero .hero-slider {
    height: max-content;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 150px;
    padding-left: 30px;
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 8px 8px;
    width: 170px;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 8px 8px;
    width: 233px;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 8px 17px;
    width: 218px;
  }

  .hero-book-card .hero-hover .hover-content {
    gap: 9px;
  }

  .home-hero .disc p {
    max-width: 79%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 12px;
  }

  .home-hero .hero-row .new-to-library {
    position: absolute;
    bottom: -120px;
    right: 0;
    width: 100%;
  }

  .home-hero .title .heading-h1 .small {
    font-size: 26px;
    line-height: 55px;
    font-weight: 100 !important;
    top: 8px;
    left: 85%;
  }

  .home-hero .new-to-library .explore {
    margin-right: 23px;
    margin-top: 7px;
  }

  .home-hero .new-to-library .new-to {
    grid-template-columns: 1fr 23px;
    margin-top: 89px;
    margin-right: 0px;
  }

  .home-hero .new-to-library .date-time {
    text-align: right;
  }

  .home-hero .new-to-library .blank::after {
    width: 40px;
    left: 30%;
    top: 16px;
  }

  .demand-card .card-image {
    width: 100%;
    height: 497px;
  }

  .demand-card .content {
    max-width: 330px;
  }

  .demand-card .number p {
    font-size: 300px;
  }

  .demand-card .number {
    left: -3px;
    top: 210px;
  }

  .demand-card .content .detail {
    padding: 28px 10px 20px;
  }

  .demand-card .hero-hover {
    top: 0;
    z-index: 2;
    width: 100%;
    height: 499px;
  }

  .most-in-demand.repeat.fiction {
    margin-top: 0px;
    margin-bottom: 9px;
  }

  .most-in-demand.repeat {
    padding-top: 16px;
  }

  .most-in-demand {
    padding-top: 60px;
  }

  .most-in-demand .top-title .title {
    margin-bottom: -9px;
    line-height: 40px;
  }

  .demand-slider {
    margin-top: 43px;
  }

  .demand-slider .splide__arrows.splide__arrows--ltr {
    right: 0;
    top: -78px;
    gap: 10px;
  }

  .most-in-demand .top-title .blank p {
    width: 96.6%;
    height: 2px;
    margin-bottom: 7px !important;
  }

  .fiction-slide-card .card-image {
    width: 284px;
    height: 424px;
  }
  .fiction-slide-card .hero-hover {
    top: 7px;
    z-index: 2;
    height: 424px;
    width: 284px;
  }

  .fiction-slide-card {
    margin-top: 9px;
  }

  .demand-slider .splide__slide {
    margin-right: 0px !important;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 32% 22.8% 19%;
    gap: 10px;
    padding-left: 30px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 20% 35.8% 18.2%;
    gap: 10px;
  }

  .most-in-demand.repeat.fiction .top-title .blank p {
    margin-bottom: 8px !important;
  }

  .most-in-demand .top-title .cta .cost-btn {
    margin-bottom: -4px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 30px;
  }

  .picked-titles {
    padding: 40px 0 84px;
    padding-left: 30px;
    width: 91%;
  }

  .picked-titles .picked-card.dark img {
    width: 160px;
  }

  .picked-titles .picked-card .picked-title {
    font-size: 90px;
    line-height: 70px;
    padding-bottom: 10px;
    padding-top: 30px;
  }

  .picked-titles .picked-card .detail {
    font-size: 22px;
    line-height: 34px;
  }

  .picked-titles .picked-card .cta {
    padding: 137px 0 0;
  }

  .picked-titles .picked-card {
    border-radius: 10px;
    padding: 24px;
  }

  .becoming-member {
    padding: 127px 0 80px;
    width: 91%;
  }

  .becoming-member .content .heading-h2 {
    padding: 18px 0px 50px 0;
    margin-bottom: 21px;
    line-height: 48px !important;
  }

  .h-before-footer .house-row {
    max-width: 91%;
    margin: 0 auto;
  }

  .h-before-footer .house-row .cta {
    margin-right: 12px;
  }

  .web-header {
    padding-top: 30px;
  }

  .web-header .cost-navbar {
    display: none;
  }

  .mob.mobile-menu-main {
    display: block;
    width: max-content;
    right: 0;
    top: 0;
  }

  .h-before-footer .title .heading-h2::after {
    width: 42%;
    right: -25px;
    bottom: 7px;
    height: 2px;
  }

  .h-before-footer .detail {
    margin-bottom: 21px;
  }

  .h-before-footer .title .heading-h2 {
    margin-bottom: 20px;
  }

  .h-before-footer {
    padding: 87px 0 84px;
    width: 91%;
  }

  .house-footer {
    padding: 60px 0px 87px 30px;
    border-top: 0;
  }

  .house-footer::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1.5px;
    background: var(--yellow);
    left: 0;
    top: 0;
  }

  .house-footer .footer-top {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
    padding-right: 30px;
  }

  .house-footer .footer-top .in-short {
    display: none;
  }

  .house-footer .reserved {
    display: block;
  }

  .house-footer .reserved p:first-child {
    line-height: 35px;
  }

  .house-footer .footer-bottom {
    grid-template-columns: 100%;
    margin-top: 33px;
  }

  .house-footer .footer-bottom .f-left {
    order: 2;
  }

  .house-footer .footer-bottom .f-right {
    display: grid;
    grid-template-columns: 60% 40%;
  }

  .house-footer .footer-bottom .f-social {
    order: 2;
  }

  .house-footer .privacy-policy {
    gap: 30px;
    padding-left: inherit;
    justify-content: flex-start;
  }

  .becoming-member .content {
    max-width: 65%;
  }

  .house-footer .reserved p {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .privacy-policy a {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .f-detail .our-story li {
    margin-bottom: 9px;
  }

  .hero-slider .splide__arrow.splide__arrow--prev {
    rotate: 0deg;
    position: absolute;
    left: -20px;
    top: 39%;
    z-index: 2;
  }

  .hero-slider .splide__arrow.splide__arrow--next {
    rotate: 0deg;
    position: absolute;
    right: -20px;
    top: 39%;
    z-index: 2;
  }

  .mobile-menu-link .top-main {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    padding: 30px;
  }

  .mobile-menu-main .mobile-menu-link .cta button {
    margin-left: auto;
  }

  .mobile-menu-main .mobile-menu-link {
    right: 97%;
    width: 91vw;
    height: 100vh;
  }

  .mobile-menu-link .mobile-link {
    padding-left: 12.5%;
    padding-top: 126px;
  }

  .mobile-menu-link .mobile-social {
    margin-top: 113px;
  }

  .mobile-menu-link .mobile-social .f-right {
    grid-template-columns: 100%;
    padding-left: 8.5%;
    padding-top: 49px;
  }

  .mobile-menu-link .social-links {
    justify-content: flex-start;
    align-items: center;
  }
  .mobile-menu-link .mobile-social .f-right .f-social {
    order: 1;
  }

  .mobile-menu-link .mobile-social .f-right .f-pvc {
    order: 2;
  }

  .join-us-modal .cost-form-group.choose-cd .c-image img {
    width: 100px;
  }

  .join-us-modal .plan-detail {
    padding: 10px 20px;
    border: 1px solid var(--green);
    margin-top: 20px;
    border-radius: 4px;
  }

  .join-us-modal .cost-form-group.choose-cd {
    grid-template-columns: 1fr 1fr !important;
    gap: 10px;
  }

  .join-us-modal .cost-form-group.choose-cd .c-image {
    position: relative;
    grid-column: 1 / span 2;
  }

  .join-us-modal .cost-form-group.choose-cd .detail {
    position: relative;
    grid-column: 1 / span 2;
  }

  .join-us-modal .choose-cards {
    position: relative;
    height: max-content;
    overflow: auto;
    padding-right: 10px;
  }

  .join-us-modal .choose-cards::-webkit-scrollbar {
    width: 3px;
  }

  .join-us-modal .login-form-main.chose-pln {
    grid-template-columns: 1fr;
    column-gap: 0px;
    gap: 10px;
  }

  .contact-form.payment-card-v1 .login-form-main.chose-pln {
    grid-template-columns: 100%;
    column-gap: 30px;
  }

  .join-us-modal .add-new .top {
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  .contact-form .login-form-main .submit-cta button {
    margin-left: auto;
    margin-bottom: 20px;
  }

  .join-us-modal .choose-cards .top1 p {
    font-size: 18px;
    padding-bottom: 10px;
    margin-top: 0px;
  }

  .plan-card .plan-cta {
    bottom: -27px;
    padding-right: 13px;
  }
  .suits89ty {
    width: 100%;
    grid-template-columns: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  :root {
    /* style guide for 1440 */
    --h1-font-size: 50px;
    --h1-line-height: 40px;

    --h2-font-size: 32px;
    --h2-line-height: 40px;

    --h3-font-size: 20px;
    --h3-line-height: 26px;

    --h4-font-size: 24px;
    --h4-line-height: 34px;

    --h5-font-size: 17px;
    --h5-line-height: 23px;

    --h6-font-size: 14px;
    --h6-line-height: 26px;

    --body-font-size: 15px;
    --body-line-height: 23px;

    --login-title: 50px;
    --login-line-height: 50px;
  }

  .order-book-card .order-n.new15 {
    grid-column: 1 / span 3;
    gap: 7px;
    margin-top: 20px;
    grid-template-columns: 100%;
  }

  .cust-delivery-time {
    margin-top: 15px;
    grid-column: 1 / -1;
  }

  .cust-delivery-time select {
    border: 1px solid var(--dark-green);
  }

  .top-row.reading-t15 .order-s-date .accept-reject:last-child {
    margin-left: 5px;
  }

  .mobile-menu-link .top-main .header-logo .fixed-menu-logo.dsk-s1 {
    display: none;
  }

  .mobile-menu-link .top-main .header-logo .fixed-for-ms1 {
    display: block;
  }

  .library-overview-hero.detail-v1 .right {
    margin-bottom: 170px;
  }

  .cost-form-group.login-show10 .pd-sh86 {
    top: 25px;
  }

  .profile1-main .s-plan {
    width: 100%;
  }

  .profile1-m1 .payment-done80 {
    width: 100%;
    overflow: auto;
  }

  .profile1-m1 .payment-done80::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgba(128, 128, 128, 0.327);
  }

  .profile1-m1 .payment-done80::-webkit-scrollbar-thumb {
    background: var(--dark-green);
    border-radius: 5px;
  }

  .sign-form-main.sign-modal {
    max-width: 90%;
    padding: 30px 0 50px;
    margin-bottom: 0;
  }

  .hero-book-card .title {
    padding-bottom: 8px;
    margin-top: 3px;
    max-width: 100%;
    height: 56px;
  }

  .detail.demand-slider .fiction-slide-card .hero-hover {
    height: 375px;
    width: 100%;
  }

  /* my account */
  .profile1-main .pro1-title {
    margin-bottom: 14px;
    margin-top: 6px;
  }

  .profile1-m1 .form-controla {
    padding: 7px 8px;
    font-style: normal;
  }

  .subscription-main.account .nav-link {
    border-radius: 10px !important;
    height: 68px;
    font-size: 13px;
    padding: 38px 9px 5px;
  }

  .top-row.reading-t15 .order-s-date {
    text-align: left;
  }

  .subscription-main.account .order-book-card .top-row {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .subscription-main.account .order-book-card .top-row .order-n {
    grid-column: 1 / span 3;
    margin-bottom: 10px;
    display: grid;
    gap: 5px;
    margin-top: 0;
  }

  .profile1-m1 .pay-history .p-row {
    grid-template-columns: 9% 30% 15.5% 30% 15%;
    margin-bottom: 10px;
    padding: 12px 11px;
    width: 190%;
  }

  .profile1-m1 .payment-done80 .p-head {
    display: grid;
    grid-template-columns: 9% 30% 15.5% 30% 15%;
    padding: 12px 11px;
    width: 190%;
  }

  .cost-order-tab .nav-link {
    padding: 7px 15px 5px !important;
    height: 40px !important;
  }

  .subscription-main.account #SubscriptionMain-tab-Subscription::after {
    height: 22.5px;
    width: 25px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account #SubscriptionMain-tab-Payment::after {
    height: 26px;
    width: 28.5px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account #SubscriptionMain-tab-profile::after {
    height: 26px;
    width: 21px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(4) .nav-link:after {
    height: 25px;
    width: 29px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(5) .nav-link:after {
    height: 23.5px;
    width: 28px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-tab .nav-item:nth-child(6) .nav-link:after {
    height: 23.5px;
    width: 28px;
    left: 10px;
    top: 8px;
  }

  .subscription-main.account .cost-order-tab.nav.nav-tabs.nav-fill {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 85%;
  }

  .subscription-main.account .order-history .tab-content {
    width: 100%;
    padding: 10px 0;
  }

  .subscription-main.account .cost-tab {
    grid-template-columns: repeat(auto-fill, 32%);
    width: 100%;
    gap: 5px;
    border: 0px;
  }

  .my-account-hero {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .profile1-m1 .pro1-title {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .order-book-card {
    grid-template-columns: 125px 1fr;
    gap: 25px;
    padding: 15px 0;
    align-items: start;
  }

  .order-book-card .book-name {
    margin-top: 5px;
  }

  .order-book-card .cta {
    margin-top: 5px;
  }

  .order-book-card .book-name h3 {
    margin-bottom: 0px;
  }

  .profile1-m1 {
    position: relative;
    padding: 30px 0 50px;
  }

  .my-account-hero .ac-title {
    max-width: 100%;
    padding-bottom: 47px;
    font-size: 37px;
    line-height: 1.2em;
  }

  .profile1-main {
    padding: 50px 0 130px;
  }

  .profile1-m1 .p1-detail {
    width: 100%;
    padding-bottom: 20px;
  }

  .profile1-m1 .pro1-tab {
    width: 100%;
    gap: 10px;
    grid-template-columns: 1fr;
  }

  .profile1-m1 .current-stack15 {
    width: 100%;
  }

  .order-history .tab-content {
    width: 68%;
    padding: 10px 0;
  }

  .cost-order-tab {
    width: 40%;
  }

  .profile1-m1 .reading-list15 {
    width: 100%;
  }

  .my-account-hero .ac-sub-title {
    padding-top: 190px;
    margin-bottom: 50px;
    padding-right: 0px;
  }

  /* end */

  .fiction-slide-card .hero-hover .hover-content {
    gap: 7px;
  }

  .overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 20px);
  }

  .demand-card .hero-hover .hover-content {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    gap: 10px;
  }
  /* our-story */

  .our-story-main {
    width: 100%;
    padding: 168px 20px 0;
  }

  .our-story-s1 {
    width: 100%;
    padding: 0 20px;
    margin-top: 25px;
  }

  .our-story-s2 {
    width: 100%;
    padding: 73px 20px 30px;
  }

  .our-story-s2 .right-s2 .image2.for-mobile {
    display: block;
    width: 374px;
    height: 374px;
  }

  .our-story-s1 .s1-row .right .r-image .img-1:last-child {
    display: none;
  }

  .our-story-s2 .top-main {
    width: 100%;
    font-size: 17px;
    padding-right: 0;
  }

  .our-story-s1 .r-image .img-1 {
    width: 374px;
    height: 374px;
    border-radius: 15px;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-right {
    height: max-content;
    padding-bottom: 23px;
  }

  .our-story-s2 .row-s2 .left-s2 {
    order: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .our-story-main::after {
    height: 40px;
    width: 84.5%;
    border-radius: 0 10px 0 0;
    bottom: -2px;
  }

  .our-story-s2 .row-s2 .right-s2 {
    padding-right: 0;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 177px;
    width: 177px;
    margin-top: 0px;
  }

  .our-story-s2 .row-s2 {
    grid-template-columns: 100%;
    margin-top: 40px;
    gap: 5.5%;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 177px;
    width: 177px;
    border-radius: 15px;
    margin-top: 0;
  }

  .our-story-s1 .s1-left .s-content {
    padding-bottom: 12px;
    line-height: 1.5em;
  }

  .our-story-s1 .s1-left {
    padding-top: 0px;
  }

  .our-story-s1 .s1-row .right .r-image {
    margin-top: 10px;
    gap: 18px;
  }

  .our-story-main .blank-bottom {
    height: 80px;
    width: 100%;
    margin-top: 76px;
  }

  .our-story-s1 .s1-row {
    grid-template-columns: 100%;
    gap: 4.3%;
  }

  .our-story-main .story-title {
    width: 100%;
    margin-top: 26px;
    text-align: center;
  }

  .our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 7px;
    line-height: 1.6em;
  }

  .our-story-s1 .s1-left .s-title {
    max-width: 88%;
    margin-bottom: 23px;
    line-height: 1.2em;
  }

  /* End */

  /* order */

  ul.cost-order-tab.nav.nav-tabs.nav-fill {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .literary-cart {
    display: grid;
    grid-template-columns: 100%;
    gap: 2%;
    margin-top: 5px;
  }

  .literary-cart .literary {
    display: none;
  }

  .literary-cart .delivery {
    display: none;
  }

  .literary-cart.mobile {
    display: block;
  }

  .literary-cart.mobile .literary {
    display: block !important;
  }

  .literary-cart.mobile .delivery {
    display: block !important;
  }

  .literary-cart .top .top-heading {
    font-size: 28px;
    line-height: 1.2em;
    margin-top: 28px;
  }

  .literary-cart .literary-row .l-row {
    grid-template-columns: 32px 1fr;
    max-height: 700px;
    padding-right: 0;
  }

  .literary-cart .literary-row .l-row .schedule-return-mob {
    grid-column: 1 / 3;
    margin-bottom: 13px;
  }

  .literary-cart .delivery .address {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    padding-bottom: 12px;
    padding-top: 16px;
  }

  .literary-cart .book-return {
    padding: 13px 0 0;
  }

  .place-order-card .book-ordered {
    padding: 20px 0 0px;
  }

  .place-order-card .book-ordered .ordered {
    padding-bottom: 8px;
    color: var(--green);
  }

  .place-order-card {
    border-radius: 0;
    padding: 0;
    background: transparent;
  }

  .literary-cart .delivery .r-side {
    color: var(--green);
  }

  .order-page-hero .hero-s-title {
    padding-top: 210px;
  }

  .order-page-hero .hero-title {
    margin-top: 10px;
    padding-bottom: 70px;
    font-size: 40px;
    width: 90%;
  }

  .subscription-main.order-now-main {
    margin-top: 42px;
  }

  .subscription-main.order-now-main .tab-content {
    margin-bottom: 60px;
  }

  .order-page-hero::after {
    display: none;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    height: 80px;
    font-size: 18px;
    padding: 43px 16px 5px;
  }

  .place-order-card .place-order {
    bottom: auto;
    left: 0;
    width: 100%;
    position: relative;
  }

  .place-order-card .place-order button.login-button.t-btn {
    width: 100%;
  }

  #OrderNowMain-tab-OrderNow::after {
    left: 20px;
    top: 16px;
  }

  .literary-cart.mobile .previous-step {
    width: 100%;
    margin-top: 50px;
  }

  .literary-cart.mobile .previous-step button {
    margin: 0 auto;
  }

  .literary-cart.mobile .previous-step button.t-btn::after {
    bottom: 0;
    right: 0;
    left: auto;
  }

  .literary-cart.mobile .previous-step button.cost-btn span {
    transform: rotate(181deg);
  }

  .literary-cart .delivery {
    padding-right: 0;
    margin-top: 20px;
  }

  .subscription-main .cost-tab {
    grid-template-columns: repeat(auto-fill, 177px);
    width: 85%;
    gap: 15px;
  }

  .subscription-main {
    width: 100%;
    padding: 0 20px;
  }

  .literary-cart .top .top-heading::after {
    right: 0px;
    top: 19px;
    width: 65%;
    height: 2px;
  }

  .literary-cart .order-book-card {
    grid-template-columns: 87px 1fr;
    gap: 8px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
  }

  .literary-cart .literary-row .c-icon img {
    margin-bottom: 12px;
  }

  .literary-cart .literary-row {
    grid-template-columns: 40px 1fr;
    max-height: 100%;
    padding-right: 0;
    overflow: visible;
  }

  .order-book-card .top-row {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .literary-cart .order-book-card .book {
    width: 80px;
    height: 120px;
  }

  .literary-cart .literary-row .c-icon {
    width: 14px;
    height: 17px;
  }

  .order-book-card .top-row .o-sub-title {
    font-size: 15px;
  }

  .order-page-hero {
    width: 100%;
    padding: 0 20px;
    overflow: hidden;
  }

  /* end */

  .thanku-page-main {
    width: 100%;
    padding-top: 266px;
  }

  .thanku-page-main .thanku-title {
    margin-top: 26px;
    margin-bottom: 7px;
    font-size: 22px;
    line-height: 1.2em;
  }

  .page-not-found {
    width: 100%;
    padding: 0 20px;
  }

  /* start */
  .sign-in-form-wrap {
    position: relative;
    width: 100%;
  }

  .login-main-wrap {
    padding-top: 165px;
    max-width: 100%;
    margin: 0 auto;
  }

  .login-main-wrap .login-subtitle {
    margin-bottom: 21px;
  }

  .sign-in-form-wrap .login-title {
    margin-bottom: 48px;
  }

  .sign-form-main.sign {
    max-width: 100%;
    padding: 0 20px;
  }

  .sign-form-main.sign .cost-form-input.w-55 {
    width: 100%;
  }

  .sign-form-main.sign .cost-form-input.w-45 {
    width: 100%;
  }

  .sign-form-main.sign .cost-form-input.w-80 {
    width: 100%;
  }

  .login-form-main .cost-form-input.w-45.c-mail-w {
    margin-top: -40px;
  }

  .login-form-main .cost-form-input.w-45.c-mail-w1 {
    margin-top: -20px;
  }

  .login-form-main .cost-form-group label.form-label {
    margin-bottom: 0px;
  }

  .login-form-main .cost-form-group {
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
  }

  .profile1-main .s-plan .plan-row {
    grid-template-columns: 43% 57%;
    padding: 10px 9px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .profile1-main .s-plan .plan-row.pay {
    grid-template-columns: 100%;
  }

  .profile1-main .s-plan .plan-row.pay .ml-auto {
    margin-left: inherit;
    margin-top: 11px;
    margin-bottom: 5px;
  }

  .login-form-main .submit-cta {
    padding-top: 2px;
  }

  .cost-form-input.w-45.c-mail-w .cost-form-group {
    margin-bottom: 0;
  }

  .sign-form-main {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 120px;
    padding: 0 20px;
  }

  .sign-in-form-wrap .login-title {
    margin-bottom: 48px;
    text-align: center;
    font-size: 34px;
    padding: 0 20px;
    max-width: 390px;
    margin: 0 auto;
    padding-bottom: 40px;
  }

  .login-form-main .submit-cta .login-button.t-btn.w-50 {
    width: 100% !important;
  }

  .sign-form-main .submit-cta .t-btn {
    margin-left: auto;
    width: 100%;
  }

  .web-header .social-left .header-whatsapp {
    height: 24px;
    width: 24px;
  }

  /* end */

  .detail.demand-slider {
    width: 100%;
    position: relative;
    padding: 0 20px;
    margin-bottom: 30px;
  }

  .detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 374px;
  }

  .detail.demand-slider .splide__slide {
    margin-right: 25px !important;
  }

  /* Contact Us */
  .contact-us-main {
    width: 100%;
    padding: 0 20px;
    margin-top: -239px;
  }

  .contact-us-main .left .address {
    border-radius: 10px;
    padding: 54px 26px 39px;
  }

  .contact-us-main .in-touch .sub-title {
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .form-controla {
    padding: 6px 5px;
  }

  .contact-us-main .contact-row {
    grid-template-columns: 100%;
    gap: 4.5%;
    margin-bottom: 110px;
  }

  .contact-form .sign-form-col {
    display: block;
  }

  .contact-form .cost-form-group {
    grid-template-columns: 100%;
    align-items: center;
  }

  .contact-form .sign-form-col .w-55 {
    width: 100%;
  }

  .contact-form .sign-form-col .w-45 {
    width: 100%;
  }

  .contact-form .no-grid .cost-form-group {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }

  .sign-form-col .tb-m {
    margin-bottom: 30px !important;
  }

  .contact-form .cost-form-group label.form-label {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .contact-form .login-form-main .cost-form-input {
    margin-bottom: 0;
  }

  .contact-us-main .in-touch .title {
    margin-bottom: 30px;
    letter-spacing: -1px;
  }

  .contact-us-main .right {
    padding-right: 0px;
  }

  .library-overview-hero.contact-us::after {
    height: 305px;
    width: 100%;
    bottom: -2px;
    border-radius: 0;
  }

  .contact-us-main .left .inquiries {
    margin-bottom: 24px;
  }

  .contact-us-main .left .address .cta {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .contact-us-main .left .address .location.cta {
    padding-left: 4px;
    font-size: 18px;
    line-height: 26px;
    margin-top: 8px;
  }

  /* join us */
  .join-questions-main .faq-row .cta {
    padding: 37px 0 70px;
  }

  .join-questions-main .faq-row .cta button {
    margin: 0 auto;
  }

  .choose-plan-main {
    width: 100%;
    padding: 0 30px;
    margin-top: -266px;
    margin-bottom: 50px;
  }

  .common-radio .select-radio label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    height: 100%;
    width: 100%;
    max-width: 85%;
  }

  .plan-card .plan-price .old-price {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
  }

  .library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 100%;
    -webkit-line-clamp: 3;
  }

  .plan-card .plan-price .new-price {
    font-size: 15px;
  }

  .faq-accordion .accordion-button::after {
    margin-top: 5px;
  }

  .join-questions-main {
    width: 93.5%;
    padding: 40px 30px;
  }

  .library-overview-hero {
    width: 93.5%;
    padding-left: 60px;
  }

  .choose-plan-main .plan-row {
    grid-template-columns: 1fr;
    gap: 53px;
    width: 100%;
  }

  .join-questions-main .faq-row .q-card-cta {
    display: none;
  }

  .join-questions-main {
    width: 100%;
    padding: 30px 20px;
  }

  .library-overview-hero.join-us::after {
    width: 100%;
    height: 306px;
    bottom: -2px;
  }

  .faq-accordion .accordion-button {
    padding: 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .faq-accordion .accordion-body {
    padding: 12px 30px 30px;
  }

  .plan-card {
    padding: 20px 10px 21px;
  }

  .join-questions-main .q-card p {
    font-size: 29px;
    line-height: 36px;
    max-width: 51%;
    padding: 48px 8px 19px 27px;
  }

  .join-questions-main .q-card {
    height: 180px;
    width: 100%;
    border-radius: 10px;
    margin-top: 21px;
  }

  .join-questions-main .faq-row {
    grid-template-columns: 100%;
    gap: 1.5%;
  }

  .login-button {
    padding: 10px 20px;
  }

  .plan-card .card-detail {
    padding-bottom: 10px;
    margin-top: 13px;
    margin-bottom: 13px;
    border-bottom: 0;
  }

  .plan-card .plan-cta {
    position: absolute;
    bottom: -21px;
    width: -webkit-fill-available;
    padding-right: initial;
  }

  .plan-card .plan-cta .login-button {
    padding: 10px 87px;
  }

  /* library detail */
  .product-detail .product {
    width: 240px;
    height: 361px;
    margin: 0 auto;
  }

  .product-detail {
    grid-template-columns: 100%;
    padding: 0 20px;
    width: 100%;
    margin-top: -100px;
  }

  .library-overview-hero.detail::after {
    display: none;
  }

  .library-overview-hero.detail .login-title {
    text-align: center;
    margin-top: 43px;
  }

  .library-overview-hero.detail .overview-detail {
    padding-bottom: 0px;
    margin-top: 20px;
    text-align: center;
  }

  .product-detail .notify-btn {
    padding: 13px 6px;
    margin-bottom: 20px;
    width: 100%;
  }

  .product-detail .add-to-cart {
    padding: 13px 20px;
    margin-bottom: 20px;
  }

  .product-detail .reading-cart {
    color: var(--dark-green);
    padding: 12px 8px;
    width: 100%;
    margin-bottom: 10px;
  }

  .product-detail .day-delivery .in-stock {
    margin-bottom: 40px !important;
  }

  .product-detail .reading-cart span {
    margin-right: 10px !important;
  }

  .product-detail .add-cart-cta {
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .product-detail .category-list ul {
    column-gap: 21px;
    row-gap: 4px;
    justify-content: flex-start;
    padding-left: 13px !important;
    margin-top: 0px;
    flex-wrap: wrap;
    margin-left: 0;
  }

  .you-like-title {
    width: 84.5%;
    padding-left: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .you-like-title {
    width: 100%;
    padding-left: 30px;
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .overview-card-row.detail {
    margin-bottom: 60px;
  }

  .overview-card-row.detail .fiction-slide-card .detail {
    padding: 0px 20px 20px;
  }

  .product-detail .book-detail .detail-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px !important;
  }

  .product-detail .book-detail {
    padding: 12px 0 42px;
    margin-top: 20px;
  }

  .library-overview-hero.detail .book-detail-con {
    padding-top: 126px;
    grid-template-columns: 100%;
  }

  .you-like-title::after {
    display: none;
  }

  .product-detail .book-category {
    display: flex;
    margin-top: 56px;
    margin-bottom: 15px;
    gap: 17px;
  }

  .product-detail .casualties {
    margin-top: 20px;
    gap: 17px;
    display: grid;
    grid-template-columns: 100%;
  }

  .library-overview-hero.detail .book-detail-con .left {
    padding-top: 8px;
  }

  .library-overview-hero.detail .reviews {
    gap: 15px;
    padding-bottom: 0px;
    margin-top: 0px;
    justify-content: center;
  }

  /* end */

  /* Library overview */

  .fiction-slide-card .detail .book-name {
    padding: 1px 0 5px;
    margin-bottom: 0;
  }

  .library-overview-hero {
    width: 100%;
    padding-left: 20px;
  }

  .overview-card-row {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 0px;
    margin-top: 100px;
  }

  .filter-mobile #divMsg {
    top: 49px;
  }

  .filter-mobile #divMsg .custom-dropdown .selected-option {
    padding: 10px 15px 15px 68px;
    font-size: 18px;
    line-height: 26px;
  }

  .selected-option.category::after {
    height: 25px;
    width: 25px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.publisher::after {
    height: 25px;
    width: 25px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .selected-option.author::after {
    height: 25px;
    width: 25px;
    top: 10px;
    left: 20px;
    background-size: cover;
  }

  .filter-mobile #divMsg .custom-dropdown::after {
    right: 15px;
    top: 22px;
    width: 11px;
    height: 8px;
  }

  .drop-filter-main {
    width: 90%;
    margin: 0 auto;
    position: relative;
    padding: 5px 0px 3px;
  }

  .filter-mobile #divMsg {
    top: 17px;
    width: 91%;
    left: 20px;
    border-radius: 10px;
    padding: 5px 4px 20px;
  }

  .overview-footer {
    position: relative;
    width: 100%;
    padding: 40px 30px;
  }

  .library-overview-hero .overview-detail {
    margin-top: 0px;
    padding-bottom: 140px;
  }

  .overview-filter-main {
    width: 100%;
    padding: 20px 20px 50px;
    margin-top: -85px;
  }

  .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
  }

  .custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
  }

  .library-overview-hero .overview-subtitle {
    padding-top: 163px;
    margin-bottom: 0;
  }

  .library-overview-hero .overview-title {
    margin-top: 16px;
  }

  .library-overview-hero.contact-us .overview-title {
    margin-top: 16px;
    -webkit-line-clamp: 3;
  }

  .library-overview-hero.contact-us .overview-detail {
    margin-top: 26px;
  }

  .library-overview-hero::after {
    height: 80px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -2px;
    border-radius: 0;
  }

  .overview-card-row .fiction-slide-card .detail {
    padding: 0px 15px 7px;
  }

  .overview-filter-main .filter-row {
    display: none;
  }

  .overview-filter-main .overview-top-title {
    display: none;
  }

  .overview-filter-main .filter-mobile {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .overview-card-row .fiction-slide-card {
    display: grid;
    grid-template-columns: 145px 1fr;
    margin-bottom: 35px;
  }

  .overview-card-row .fiction-slide-card .card-image {
    height: 200px !important;
    width: 133px !important;
    border-radius: 10px;
  }

  .overview-card-row .fiction-slide-card .hero-hover {
    height: 200px !important;
    width: 133px;
    border-radius: 5px;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 10px 17px;
    width: calc(100% - 50px);
    font-size: 15px;
    line-height: 23px;
  }

  .overview-footer .load-row {
    grid-template-columns: 100%;
  }

  .overview-footer .load-more button {
    margin-left: auto;
    margin-right: auto;
  }

  .overview-footer .go-to-top {
    display: none;
  }

  .overview-card-row .fiction-slide-card .detail .more-info {
    font-size: 15px;
    line-height: 23px;
  }

  /* end */

  .demand-slider .splide__arrows.splide__arrows--ltr {
    display: none;
  }

  .picked-titles .picked .pic-cell:last-child {
    display: none;
  }

  .picked-titles .picked {
    grid-template-columns: 1fr;
    gap: 2px;
  }

  .picked-titles .border-right {
    border-right: 0px;
    border-radius: 0px;
  }

  .home-hero .hero-left .sub-title .heading-h4 {
    font-size: 15px;
    line-height: 23px;
    max-width: 93%;
    margin: 0 auto;
  }

  .demand-card .content .detail .category {
    font-size: 13px;
    line-height: 30px;
  }

  .fiction-slide-card .detail .category {
    font-size: 13px;
    line-height: 30px;
    font-weight: 400;
  }

  .home-hero .hero-row {
    width: 100%;
    display: block;
  }

  .home-hero .title .heading-h1 {
    max-width: 100%;
    line-height: 50px;
    font-size: 40px;
  }

  .header-logo-main .header-logo {
    width: 69px;
    height: 70px;
  }

  .header-logo-main {
    grid-template-columns: 1fr 1fr;
    gap: 2px;
    padding-left: 20px;
    padding-right: 20px;
    width: 93%;
  }

  .mobile-menu-link .mobile-link .link {
    margin-bottom: 29px;
    text-align: center;
  }

  .mobile-menu-link .mobile-link {
    padding-left: 0;
    max-width: 80%;
    margin: 0 auto;
    padding-bottom: 30px;
    margin-top: 93px;
  }

  .mobile-menu-link .mobile-social .f-right {
    grid-template-columns: 100%;
    padding-left: 0;
    padding-top: 21px;
  }

  .mobile-menu-link .privacy-policy {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 3px;
    margin-top: 14px;
  }

  .mobile-menu-link .mobile-social .f-right .f-social {
    order: 1;
  }

  .mobile-menu-link .top-main .header-logo {
    height: 69px;
    width: 70px;
  }

  .mobile-menu-link .mobile-social .f-right .f-pvc {
    order: 2;
  }

  .web-header .left-sidebar .cost-link {
    font-size: 16px;
    line-height: 35px;
    padding-top: 31px;
  }

  .mobile-menu-link .mobile-link .cost-link {
    font-size: 34px;
    line-height: 34px;
    color: var(--white) !important;
    text-transform: uppercase;
  }

  .web-header .social-left .top {
    padding-top: 0px;
  }

  .web-header .social-left .social-top {
    gap: 5px;
    margin-top: 19px;
    display: grid;
    grid-template-columns: 21% 18% 58%;
    padding-left: 10px;
    justify-content: normal;
    align-items: center;
  }

  .web-header .social-left .social-top .mobile-left {
    margin-left: 126px;
  }

  .mobile-menu-main .mobile-menu button {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
  }

  .web-header .left-sidebar .link {
    width: 40px;
  }

  .web-header .social-left {
    width: 100%;
    grid-template-columns: 80% 20%;
  }

  .hero-book-card .card-image {
    height: 380px;
    width: 100%;
  }

  .most-in-demand .splide__list .splide__slide:first-child {
    margin-left: 500px !important;
  }

  .hero-book-card .hero-hover {
    height: 381px;
    width: 100%;
  }

  .hero-book-card .sub-title p {
    font-size: 13px;
    line-height: 21px;
  }

  .hero-book-card .sub-title {
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .hero-book-card .card-content {
    position: relative;
    padding: 0 11px;
  }

  .home-hero {
    width: 100%;
    padding-left: 0;
  }

  .home-hero .hero-row .hero-left {
    padding-left: 20px;
  }

  .web-header .left-sidebar {
    height: 60px;
    width: 100%;
    right: 0;
    top: auto;
    bottom: 0;
    left: auto;
  }

  .mobile-menu-main .mobile-menu {
    width: 70px;
    height: 60px;
    right: calc(57% - 60px);
    left: auto;
    top: auto;
    bottom: 0;
  }

  .mobile-menu-main .mobile-menu-link {
    position: absolute;
    background: var(--green);
    top: auto;
    bottom: 100%;
    width: 114vw;
    right: auto;
    left: auto;
    height: 94vh;
    margin-right: auto;
    /* transform: translateX(-100vh); */
    transition-delay: 0s, 3s;
    transition: all 0.3s ease-out 0s !important;
  }

  .mobile-menu.active .mobile-menu-link {
    /* transform: translateX(0) !important; */
    transition-delay: 0s, 3s;
    transition: all 0.3s ease-out 0s !important;
  }

  .header-logo-main .member {
    display: none;
  }

  .house_container {
    width: 100%;
    padding-right: 20px;
  }

  .home-hero .hero-left .sub-title {
    padding-top: 217px;
  }

  .web-header .social-left .bottom {
    display: flex;
    align-self: end;
    margin-bottom: auto;
    margin-top: auto;
  }

  .home-hero .hero-left .title {
    padding: 7px 0 7px;
    text-align: center;
  }

  .home-hero .sub-title .heading-h4 {
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
  }

  .hero-slider .splide__track .splide__list li {
    height: calc(100% - 142px) !important;
  }

  .h-before-footer .house-row {
    max-width: 100%;
    padding: 0 30px;
  }

  .home-hero::after {
    display: none;
  }

  .home-hero .new-to-library::before {
    width: 105.5%;
    height: 347px;
    border-radius: 0;
    bottom: -60px;
  }

  .home-hero .new-to-library .date-time p {
    padding-top: 0px;
    text-align: right;
  }

  .most-in-demand .top-title {
    grid-template-columns: 56% 0% 44%;
    gap: 0;
    padding-left: 20px;
  }

  .house-footer .f-detail .our-story {
    padding-left: 0;
    column-count: 1;
  }

  .demand-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .hero-slider .splide__arrow {
    height: 52px;
    width: 52px;
  }

  .home-hero .hero-slider {
    height: max-content;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 150px;
    padding-left: 20px;
  }

  .home-hero .hero-slider .splide__arrows {
    display: none;
  }

  .hero-book-card .hero-hover .hover-content .info {
    padding: 8px 8px;
    width: 190px;
  }

  .hero-book-card .hero-hover .hover-content {
    gap: 9px;
  }

  .home-hero .disc p {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .home-hero .hero-row .new-to-library {
    position: absolute;
    bottom: -95px;
    right: 0;
    width: 100%;
  }

  .home-hero .title .heading-h1 .small {
    font-size: 20px;
    line-height: 54px;
    font-weight: 100 !important;
    top: 16px;
    left: 90%;
  }

  .home-hero .new-to-library .explore {
    margin-right: 25px;
    margin-top: 7px;
  }

  .home-hero .new-to-library .new-to {
    grid-template-columns: 100%;
    margin-top: 89px;
    margin-right: 0;
  }

  .most-in-demand .top-title .title {
    line-height: 40px;
  }

  .home-hero .new-to-library .library {
    text-align: right;
    padding-right: 23px;
  }

  .demand-card .content .detail .book-name {
    padding: 1px 0 9px;
    height: 56px;
  }

  .fiction-slide-card .hero-hover {
    position: absolute;
    top: 7px;
    z-index: 2;
    height: 372px;
    width: 100%;
  }

  .fiction-slide-card .hero-hover .hover-content .info {
    padding: 7px 5px;
    line-height: 13px;
    font-size: 10px;
  }

  .demand-card .hero-hover .hover-content .info {
    padding: 7px 5px;
    width: 160px;
    line-height: 13px;
    font-size: 10px;
  }

  .fiction-slide-card .info.more-info .info-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }

  .demand-card .info.more-info .info-icon {
    height: 20px;
    width: 20px;
  }

  .hero-book-card .info.more-info .info-icon {
    height: 20px;
    width: 20px;
  }

  .fiction-slide-card .info.cart .info-icon {
    height: 14px;
    width: 16px;
    margin-right: 5px;
  }

  .fiction-slide-card .info.reading .info-icon {
    height: 12px;
    width: 12px;
    margin-right: 10px;
  }

  .demand-slider .splide__track {
    padding-top: 7px;
  }

  .home-hero .new-to-library .blank::after {
    width: 98%;
    left: 7%;
    top: 12px;
  }

  .demand-card .card-image {
    width: 90%;
    height: 335px;
  }

  .demand-card .hero-hover {
    height: 336px;
    width: 90%;
  }

  .demand-card .content {
    max-width: 330px;
  }

  .demand-card .number p {
    font-size: 300px;
  }

  .demand-card .number {
    left: -65px;
    top: 35%;
  }

  .demand-card .content .detail {
    padding: 14px 10px 10px;
  }

  .most-in-demand.repeat {
    padding-top: 30px;
  }

  .fiction-slide-card .detail {
    padding: 14px 0px 10px 0px;
  }

  .most-in-demand {
    padding-top: 80px;
  }

  .demand-slider {
    margin-top: 27px;
    padding-left: 20px;
  }

  .most-in-demand .top-title .blank p {
    display: none;
  }

  .fiction-slide-card .card-image {
    width: 100%;
    height: 371px;
  }

  .demand-card .content .detail .book-name {
    padding: 1px 0 9px;
  }

  .demand-slider .splide__slide {
    margin-right: 30px !important;
  }

  .most-in-demand.repeat .top-title {
    grid-template-columns: 61% 0 39%;
    gap: 0;
    padding-left: 20px;
  }

  .most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 43% 0% 56%;
    gap: 0;
    padding-left: 20px;
  }

  .most-in-demand.repeat.fiction {
    margin-top: 67px;
    margin-bottom: 28px;
  }

  .most-in-demand.repeat .demand-slider {
    padding-left: 20px;
  }

  .picked-titles {
    padding: 82px 0 82px;
    padding-left: 20px;
    width: 100%;
  }

  .picked-titles .picked-card.dark img {
    width: 160px;
  }

  .picked-titles .picked-card .picked-title {
    font-size: 90px;
    line-height: 68px;
    padding-bottom: 8px;
    padding-top: 20px;
  }

  .picked-titles .picked-card .detail {
    font-size: 22px;
    line-height: 34px;
  }

  .picked-titles .picked-card .cta {
    padding: 137px 0 0;
  }

  .picked-titles .picked-card {
    border-radius: 10px;
    padding: 29px 10px 15px;
  }

  .becoming-member {
    padding: 105px 40px;
    width: 100%;
  }

  .becoming-member::after {
    position: absolute;
    left: 0;
    top: 0;
    background: #0f2620;
    content: "";
    height: 100%;
    width: 100%;
    opacity: 50%;
  }

  .becoming-member .content .heading-h2 {
    padding: 10px 0px 15px 0;
    margin-bottom: 26px;
    line-height: 42px !important;
    font-size: 22px;
  }

  .becoming-member .content .detail {
    margin-bottom: 30px !important;
  }

  .house-footer::before {
    width: 100%;
  }

  .web-header {
    padding-top: 20px;
  }

  .web-header .cost-navbar {
    display: none;
  }

  .mob.mobile-menu-main {
    display: block;
    height: 0;
  }

  .h-before-footer .title .heading-h2::after {
    display: none;
  }

  .h-before-footer .detail {
    margin-bottom: 26px;
    text-align: center;
  }

  .h-before-footer .house-row .cta {
    text-align: right;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .h-before-footer .title .heading-h2 {
    margin-bottom: 13px;
    text-align: center;
  }

  .h-before-footer .title .heading-h2 span {
    display: block;
  }

  .h-before-footer {
    padding: 79px 0 84px;
    width: 100%;
  }

  .house-footer {
    padding: 43px 0px 87px 20px;
  }

  .house-footer .footer-top {
    grid-template-columns: 1fr;
    gap: 0px;
    padding-right: 0px;
  }

  .house-footer .footer-top .f-title::after {
    display: none;
  }

  .picked-titles .picked-card.dark {
    width: 100%;
  }

  .house-footer .footer-top .address {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
  }

  .house-footer .footer-top .contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .house-footer .footer-top .business-hours {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 35px;
  }

  .house-footer .footer-top .in-short {
    display: none;
  }

  .house-footer .reserved {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .house-footer .reserved p:first-child {
    line-height: 35px;
  }

  .house-footer .footer-bottom {
    grid-template-columns: 100%;
    margin-top: 19px;
  }

  .house-footer .footer-bottom .f-left {
    order: 2;
  }

  .house-footer .footer-bottom .f-right {
    display: grid;
    grid-template-columns: 1fr;
  }

  .house-footer .footer-bottom .f-social {
    order: 0;
  }

  .social-links {
    display: flex;
    padding-top: 11px;
    padding-bottom: 13px;
    justify-content: center;
    align-items: center;
  }

  .house-footer .privacy-policy {
    gap: 30px;
    padding-left: 0;
    justify-content: center;
    margin-top: 9px;
  }

  .becoming-member .content {
    max-width: 100%;
    text-align: center;
  }

  .becoming-member .house_container {
    width: 100%;
    padding-right: 0;
  }

  .becoming-member .content .cta .white-btn-w {
    margin: 0 auto;
  }

  .house-footer .reserved p {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .privacy-policy a {
    font-size: 14px;
    line-height: 22px;
  }

  .house-footer .f-detail .our-story li {
    margin-bottom: 9px;
  }

  .hero-slider .splide__arrow.splide__arrow--prev {
    rotate: 0deg;
    position: absolute;
    left: 5px;
    top: 39%;
    z-index: 2;
  }

  .hero-slider .splide__arrow.splide__arrow--next {
    rotate: 0deg;
    position: absolute;
    right: -20px;
    top: 39%;
    z-index: 2;
  }

  .mobile-menu-main .mobile-menu {
    width: 70px;
    background: var(--white);
    height: 60px;
  }

  .mobile-menu button span:last-child {
    background: var(--green);
    width: 34px;
    left: 18px;
    bottom: 24px;
  }

  .mobile-menu button span:first-child {
    background: var(--green);
    width: 34px;
    height: 2px;
    top: 24px;
    left: 18px;
  }

  .mobile-menu-main .mobile-menu.active {
    background: var(--green);
  }

  .mobile-menu-main .mobile-menu.active button span:last-child {
    background: var(--white);
    width: 34px;
    bottom: 49%;
    left: 26%;
  }

  .mobile-menu-main .mobile-menu.active button span:first-child {
    background: var(--white);
    width: 34px;
    height: 2px;
    top: 49%;
    left: 26%;
  }

  .mobile-menu-main .mobile-menu-link .cta button {
    display: none;
  }

  .mobile-menu-link .top-main a.sub-logo {
    display: block;
  }

  .mobile-menu-link .top-main {
    display: grid;
    grid-template-columns: 120px 1fr;
    max-width: 100%;
    padding: 27px 35px;
    padding-left: 53px;
  }

  .mobile-menu-link .top-main a.sub-logo img {
    width: 140px;
  }

  button.header-btn-y.t-btn.n-o-mobile {
    display: none;
  }

  .house-header .show-on-mobile {
    display: block;
  }

  .house-header .show-on-mobile img {
    width: 140px;
  }

  .s-mobile {
    display: block;
  }

  .picked-titles .border-right .mobile {
    display: block;
    margin: 0 auto;
    padding: 15px 0 10px;
  }

  .picked-titles .border-right .mobile.t-btn::after {
    bottom: 7px;
    left: 0;
  }

  .hero-left .show-mobile {
    display: grid;
    margin: 0 auto;
    width: 88%;
    padding-top: 28px;
    grid-template-columns: 56% 1fr;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  /* join modal */
  .join-us-modal .add-new .new-title {
    font-size: 15px;
    line-height: 1.2em;
  }

  .join-us-modal .add-new .top {
    grid-template-columns: 1fr;
    justify-content: left;
    align-items: center;
    padding: 10px 0px;
    margin-bottom: 20px;
  }

  .join-us-modal .add-new .top img {
    width: 190px;
    margin-left: inherit;
  }

  .join-us-modal .login-form-main.chose-pln {
    grid-template-columns: 1fr;
    column-gap: 0px;
  }

  .join-us-modal .cost-form-group.choose-cd {
    grid-template-columns: 1fr 1fr !important;
    gap: 10px;
  }

  .join-us-modal .cost-form-group.choose-cd .c-image {
    position: relative;
    grid-column: 1 / span 2;
  }

  .join-us-modal .cost-form-group.choose-cd .detail {
    position: relative;
    grid-column: 1 / span 2;
  }

  .join-us-modal .cost-form-group.choose-cd .pay-now {
    grid-template-columns: 1fr 1fr;
  }

  .join-us-modal .modal-title.h4 {
    font-size: 18px;
    font-weight: 700;
  }

  .join-us-modal .plan-detail {
    padding: 4px 14px;
    border: 1px solid var(--green);
    margin-top: 12px;
    border-radius: 4px;
  }

  .join-us-modal .cost-form-group .form-label {
    margin-bottom: 5px !important;
    margin-right: 0 !important;
    font-size: 14px;
    line-height: 1.2em;
    padding-left: 0px;
  }

  .join-us-modal .login-form-main .cost-form-group {
    margin-bottom: 8px;
    grid-template-columns: 1fr;
  }

  .join-us-modal .choose-cards .top1 p {
    font-size: 16px;
    padding-bottom: 5px;
    margin-top: 0;
  }

  .contact-form .login-form-main .submit-cta {
    padding-top: 4px;
    margin-bottom: 20px;
  }

  .join-us-modal .cost-form-group.choose-cd .c-image img {
    width: 55px;
  }

  .join-us-modal .cost-form-group.choose-cd .pay-now .login-button {
    font-size: 14px;
    line-height: 1.2em;
    padding: 6px 17px;
    border-radius: 4px;
  }

  .join-us-modal .choose-cards {
    position: relative;
    height: max-content;
    overflow: auto;
    padding-right: 10px;
  }

  .join-us-modal .choose-cards::-webkit-scrollbar {
    width: 3px;
  }

  .join-us-modal .cost-form-group .form-controla {
    font-size: 13px !important;
  }

  .join-us-modal .modal-header {
    padding: 5px 25px 10px;
  }

  .join-us-modal .form-controla {
    padding: 6px 0px;
  }

  .library-overview-hero.faq::after {
    width: 100%;
    height: 306px;
  }

  .join-questions-main.faq .right {
    width: 100%;
    margin: 0 auto;
  }

  .join-questions-main.faq {
    margin-top: -295px;
    padding-bottom: 60px;
  }

  .library-overview-hero.faq .overview-title {
    padding-bottom: 360px;
    line-height: 50px;
    -webkit-line-clamp: 3;
  }

  .library-overview-hero.terms::after {
    width: 100%;
    height: 306px;
  }

  .library-overview-hero.terms .overview-subtitle {
    padding-top: 168px;
    padding-bottom: 384px;
    width: 100%;
    line-height: 50px;
  }

  .t-and-c-main {
    position: relative;
    margin-top: -270px;
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    padding: 0 20px;
    gap: 10px;
  }

  .t-and-c-main .right-content {
    padding-right: 0px;
  }

  /* term */

  .t-and-c-main .left-main {
    display: none;
  }

  .term-mobile .dropdown-toggle {
    background: transparent;
    border: 1px solid var(--green);
    width: 100%;
    color: var(--green);
    text-align: left;
  }

  .term-mobile .dropdown-menu.show {
    width: 100%;
    position: absolute;
    margin-top: -4px;
    border-radius: 0 0 5px 5px;
  }

  .term-mobile .dropdown-menu.show .dropdown-item {
    white-space: break-spaces;
  }

  .web-header .social-left .searchdiv {
    position: absolute;
    right: auto;
    left: 12px;
    top: -71px;
    width: 350px;
    z-index: 999;
  }

  .web-header .social-left .show-on-click {
    border-radius: 5px;
    bottom: 98px;
    left: 11px;
    max-height: 200px;
    overflow: auto;
    padding: 10px 20px;
    top: auto;
  }

  .web-header .social-left .searchdiv .input-group input.form-control {
    order: 2;
    border-radius: 0 5px 5px 0 !important;
  }

  .web-header .social-left .searchdiv .input-group button {
    border-radius: 5px 0 0 5px !important;
  }

  .overview-clear-filter {
    padding-right: 0px;
    grid-template-columns: 69% 26%;
    gap: 5%;
    margin-bottom: 10px;
  }

  .login-subtitle.reg10 {
    color: var(--yellow);
  }

  .sign-in-form-wrap .have-account {
    margin-bottom: 20px;
  }

  .for-mobile450 {
    display: block;
    margin-top: -30px;
    color: var(--green);
    position: relative;
  }

  .for-mobile450::after {
    position: absolute;
    content: "";
    height: 1.5px;
    width: 60px;
    left: 42%;
    bottom: -3px;
    background: var(--green);
  }

  .t-and-c-main .left .term-mobile {
    display: block !important;
  }

  .house-header.fix-header .header-logo-main .header-logo {
    display: none;
  }

  .house-header.fix-header .header-logo-main {
    display: none;
  }

  .suits89ty {
    width: 100%;
    grid-template-columns: 100%;
  }

  .header-logo .mobile20-logo {
    display: block;
  }

  .header-logo .desktop-logo {
    display: none;
  }

  .contact-form.payment-card-v1 .login-form-main.chose-pln {
    grid-template-columns: 100%;
    column-gap: 10px;
  }
}

@media (min-width: 370px) and (max-width: 412px) {
  .our-story-main .story-title {
    width: 100%;
    margin-top: 26px;
    text-align: center;
    font-size: 44px;
  }

  .home-hero .title .heading-h1 {
    max-width: 100%;
    line-height: 50px;
    font-size: 37px;
  }

  .our-story-s2 .right-s2 .image2.for-mobile {
    display: block;
    width: 100%;
    height: 375px;
  }

  .our-story-s1 .r-image .img-1 {
    width: 100%;
    height: 370px;
    border-radius: 15px;
  }

  .our-story-s2 .left-s2 .image1 {
    height: 177px;
    width: 98%;
    margin-top: 0px;
  }

  .our-story-s2 .left-s2 .image2 {
    height: 177px;
    width: 98%;
    border-radius: 15px;
    margin-top: 0;
  }

  .cost-order-tab .nav-link {
    padding: 7px 2px 5px !important;
    height: 40px !important;
  }

  .subscription-main .nav-link {
    border-radius: 10px !important;
    height: 80px;
    font-size: 15px;
    padding: 41px 11px 5px;
  }

  .subscription-main .cost-tab {
    grid-template-columns: repeat(auto-fill, 150px);
    width: 85%;
    gap: 15px;
  }

  .home-hero .hero-row .hero-left {
    padding-left: 15px;
  }

  .house_container {
    padding-right: 15px;
  }

  .home-hero .disc p {
    font-size: 14px;
  }

  .suits89ty {
    width: 100%;
    grid-template-columns: 100%;
  }

  .contact-form.payment-card-v1 .login-form-main.chose-pln {
    grid-template-columns: 100%;
    column-gap: 10px;
  }

  .contact-form.payment-card-v1 .cost-form-group .form-label {
    font-size: 14px;
    margin-top: 5px;
  }
}
